import { Box, Tooltip } from "@mui/material";
import { CustomIcon } from "../../common";
import { SideBarOption } from "./types";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import CustomLink from "./CustomLink";
import { usePermissions } from "../../../core/navigation/usePermissions";

type SidebarOptionProps = {
  option: SideBarOption;
};

const SidebarOption = forwardRef<HTMLDivElement, SidebarOptionProps>(
  ({ option }, ref) => {
    const { t } = useTranslation();
    const { useHasPermission } = usePermissions();

    const hasPermission = useHasPermission(option.permission);

    return (
      <>
        {hasPermission || !option.permission ? (
          <Tooltip key={option.title} title={t(option.title)} placement="right">
            <Box key={option.title}>
              <CustomLink to={option.redirectURL}>
                <Box
                  key={option.title}
                  sx={{ display: "flex", alignItems: "center" }}
                  ref={ref}
                >
                  <CustomIcon
                    name={option.icon}
                    width={"32px"}
                    height={"28px"}
                  />
                </Box>
              </CustomLink>
            </Box>
          </Tooltip>
        ) : null}
      </>
    );
  },
);

export default SidebarOption;
