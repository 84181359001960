import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../ProductsEditableTable";

export const BodyAndLidPackagingMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field: "weight_per_litre_body",
    label: "micro:packagingSection.table.body_weight",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "recycled_content_body",
    label: "micro:packagingSection.table.body_recycled_content",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "recovery_rate_body",
    label: "micro:packagingSection.table.body_recovery_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "weight_per_litre_lid",
    label: "micro:packagingSection.table.lid_weight",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "recycled_content_lid",
    label: "micro:packagingSection.table.lid_recycled_content",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "recovery_rate_lid",
    label: "micro:packagingSection.table.lid_recovery_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "net_breakage_rate",
    label: "micro:packagingSection.table.net_loss_rate",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
];
