import "./Scenario.scss";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Typography } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getDateFormat } from "../../../../utils";
import {
  UpdateMacroScenarioViewModel,
  ViewMacroScenarioViewModel,
} from "../../../../orval/generated/models";
import UpdateDetailsModal from "../../../../components/common/update-scenario-modal/UpdateDetailsModal";
import { useModal } from "../../../../components/common/modal";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "../../../../components/common/notification/showSnackbar";
import { useGlobalLoader } from "../../../../components";
import ThreeDotMenu from "../../../../components/common/three-dot-menu/ThreeDotMenu";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import {
  getGetAllMacroScenariosScenariosMacroGetQueryKey,
  useDeleteScenarioByIdScenariosScenarioIdDelete,
  useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost,
  useUpdateMacroScenarioScenariosMacroScenarioIdPatch,
} from "../../../../orval/generated/endpoint";

type Props = {
  data: ViewMacroScenarioViewModel;
  selectedOptions: string[];
  scenarioId: number;
  onChangeSelectOption: (
    item: number,
    checked: boolean,
    year: number | null,
  ) => void;
};

function SingleScenario({
  data,
  selectedOptions,
  scenarioId = 0,
  onChangeSelectOption,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["macro", "common"]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { openModal, closeModal } = useModal();
  const showSnackbar = useSnackbar();
  const queryClient = useQueryClient();

  const { showGlobalLoader } = useGlobalLoader();

  // Function to toggle the state to show all summaries
  const handleSeeMoreClick = () => {
    setShowAll(!showAll);
  };

  const handleCheckboxClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation(); //To avoid expanding accordion on clicking on the checkBox
  };

  const handleViewScenario = (scenarioId: string): void => {
    navigate(`/country-level-analytics/scenario/${scenarioId}`);
  };

  const { mutateAsync: updateMacroScenario } =
    useUpdateMacroScenarioScenariosMacroScenarioIdPatch({
      mutation: {
        onSuccess: () => {
          navigate(`/country-level-analytics/`);
          showSnackbar("Macro scenario updated successfully", "success");
          queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          closeModal();
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(error, "error");
          return false;
        },
      },
    });

  const handleUpdateDetails = async (
    scenarioId: number,
    data: UpdateMacroScenarioViewModel,
  ): Promise<boolean> => {
    try {
      await updateMacroScenario({
        scenarioId,
        data,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onHandleUpdateDetails = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      if (data) {
        openModal(
          <UpdateDetailsModal
            open={true}
            scenario={data}
            closeModal={closeModal}
            updateScenarioFunction={handleUpdateDetails}
          ></UpdateDetailsModal>,
        );
      }
    },
    [data, openModal, closeModal],
  );

  const { mutate: duplicateScenario, isPending: duplicateScenarioIsPending } =
    useDuplicateMacroScenarioScenariosMacroScenarioIdDuplicatePost({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
          });
          showSnackbar(t("micro:notifications.scenarioDuplicated"), "success");
          showGlobalLoader(false);
        },
        onError: (error: string | Error | string[] | AxiosError) => {
          showSnackbar(t("micro:errorMessages.errorDuplicating"), "error");
        },
      },
    });

  const onHandleDuplicateScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      const scenarioId: number = parseInt((event.target as Element).id);
      duplicateScenario({ scenarioId });
    },
    [duplicateScenario],
  );

  const {
    mutate: deleteMacroScenario,
    isPending: deleteMacroScenarioIsPending,
  } = useDeleteScenarioByIdScenariosScenarioIdDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetAllMacroScenariosScenariosMacroGetQueryKey(),
        });
        showSnackbar(
          t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.scenarioDeleted",
          ),
          "success",
        );
        showGlobalLoader(false);
        closeModal();
      },
      onError: (error: string | Error | string[] | AxiosError) => {
        showSnackbar(error, "error");
      },
    },
  });

  const onHandleDeleteScenario = useCallback(
    (event: Event | SyntheticEvent<Element, Event>) => {
      openModal(
        <ConfirmationModal
          title={`${t("micro:microViewAllScenariosPage.deleteScenarioModal.title")} ${data.name}${t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.questionMark",
          )}`}
          description={t(
            "micro:microViewAllScenariosPage.deleteScenarioModal.description",
          )}
          actionTitle={t("common:actions.delete")}
          confirmAction={() => {
            deleteMacroScenario({ scenarioId: scenarioId });
            closeModal();
          }}
          cancelAction={closeModal}
        />,
      );
    },
    [data],
  );

  const macroThreeDotsMenuItems = {
    menuItems: [
      {
        label: t("common:actions.updateDetails"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) =>
          onHandleUpdateDetails(event),
        id: scenarioId,
      },
      {
        label: t("common:actions.duplicate"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) => {
          onHandleDuplicateScenario(event);
        },
        id: scenarioId,
      },
      {
        label: t("common:actions.delete"),
        onClick: (event: Event | React.SyntheticEvent<Element, Event>) => {
          onHandleDeleteScenario(event);
        },
        id: scenarioId,
      },
    ],
  };

  useEffect(() => {
    deleteMacroScenarioIsPending ||
    duplicateScenarioIsPending ||
    deleteMacroScenarioIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    deleteMacroScenarioIsPending,
    duplicateScenarioIsPending,
    deleteMacroScenarioIsPending,
  ]);

  return (
    // COMMENTED OUT ACCORDION
    // <Accordion
    //   className="scenarioSummary"
    //   sx={{
    //     border: "1px solid",
    //     borderColor: `${palette.textColor.light}`,
    //     borderRadius: "5px",
    //     padding: "0 16px",
    //     paddingLeft: "8px",
    //     margin: "16px 0",
    //     marginBottom: "32px",
    //   }}
    //   onChange={() => setShowAll(false)}
    // >

    // <AccordionSummary
    //   sx={{
    //     "& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
    //       marginTop: "12px",
    //     },
    //   }}
    //   expandIcon={
    //     <KeyboardArrowDownIcon sx={{ fontSize: 30 }} color="primary" />
    //   }
    // >
    <Box
      className="scenario-tile"
      key={data.id}
      sx={{ display: "flex", justifyContent: "space-between" }}
      my={2}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems={data.description ? "flex-start" : "center"}
      >
        {/*  TODO: business would like compare disabled for now*/}
        {/*<Box>*/}
        {/*  <Checkbox*/}
        {/*    onClick={handleCheckboxClick}*/}
        {/*    onChange={(_e, checked) =>*/}
        {/*      onChangeSelectOption(*/}
        {/*        data?.id!,*/}
        {/*        checked,*/}
        {/*        data?.inputs?.years?.base_year ?? 0,*/}
        {/*      )*/}
        {/*    }*/}
        {/*    disabled={*/}
        {/*      !selectedOptions.includes(`${data?.id}`) &&*/}
        {/*      selectedOptions.length >= 5*/}
        {/*    }*/}
        {/*    checked={selectedOptions.some(*/}
        {/*      (selectedItem) => `${selectedItem}` === `${data.id}`,*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Box>*/}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"stretch"}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
              }}
              ml={1}
              mr={1}
              className={"scenario-title"}
              onClick={() => {
                handleViewScenario(data.id!.toString());
              }}
            >
              {data?.name}
            </Typography>
          </Box>
          {data.description ? (
            <Box ml={1} mr={1}>
              <Typography variant={"body1"}>{data?.description}</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography sx={{ fontWeight: "500" }}>
          {data?.inputs?.years?.base_year !== null &&
          data?.inputs?.years?.target_year !== null
            ? `${data?.inputs?.years?.base_year} - ${data?.inputs?.years?.target_year}`
            : ""}
        </Typography>

        {data?.inputs?.years?.base_year !== null &&
          data?.inputs?.years?.target_year !== null && (
            <FiberManualRecordIcon
              sx={{
                m: 1,
                fontSize: "5px",
                color: "black",
              }}
            />
          )}

        {/*{data.adjustments_count ? (*/}
        {/*  <>*/}
        {/*    <Typography sx={{ fontWeight: "500" }}>*/}
        {/*      {data.adjustments_count} {t("adjustments")}*/}
        {/*    </Typography>*/}
        {/*    <FiberManualRecordIcon*/}
        {/*      sx={{*/}
        {/*        m: 2,*/}
        {/*        fontSize: "5px",*/}
        {/*        color: `${palette.textColor.dark}`,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*) : null}*/}
        <Tooltip title={t("common:labels.updatedDate")}>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <UpdateIcon sx={{ fontSize: "16px", margin: "0 3px" }} />
            <Typography sx={{ fontWeight: "500" }}>
              {getDateFormat(data?.updated_at!)}
            </Typography>
          </Box>
        </Tooltip>
        <Box>
          <ThreeDotMenu
            menuItems={macroThreeDotsMenuItems.menuItems}
          ></ThreeDotMenu>
        </Box>
      </Box>
    </Box>
    //   {/*</AccordionSummary>*/}
    //   {/*TODO: business would like adjustments disabled for now*/}
    // {/*  <Box display={"flex"} flexDirection={"row-reverse"}>*/}
    // {/*    <Button*/}
    // {/*      sx={{ marginBottom: 2, marginTop: 2 }}*/}
    // {/*      onClick={() => {*/}
    // {/*        handleViewScenario(data.id!.toString());*/}
    // {/*      }}*/}
    // {/*    >*/}
    // {/*      {t("viewScenario")}*/}
    // {/*    </Button>*/}
    // {/*  </Box>*/}
    // {/*</Accordion>*/}
    // );
  );
}

export default SingleScenario;
