import CompareKPIsTable from "./CompareKPIsTable";

import { AccordionComponent } from "../../common";
import { useTranslation } from "react-i18next";
import {ViewMacroScenarioViewModel} from "../../../../../orval/generated/models";

type Props = {
  scenarios: ViewMacroScenarioViewModel[] | null;
  kpiKey: string;
};
function CompareScenariosKPIAccordion({ scenarios, kpiKey }: Props) {
  const { t } = useTranslation("macro");

  return (
    <AccordionComponent title={t(`kpiTable.${kpiKey}`)}>
      {scenarios && <CompareKPIsTable kpiKey={kpiKey} scenarios={scenarios} />}
    </AccordionComponent>
  );
}

export default CompareScenariosKPIAccordion;
