import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import {
  CompareScenarioHeader,
  CompareScenarioHeroSection,
} from "../components/compare-scenario";
import { MacroToolRoutesConfig } from "../navigation";
import { Tablist, useGlobalLoader } from "../../../components";
import Modal from "../../../components/common/modal/Modal";
import { CompareScenarioModal } from "../components/compare-scenario-modal/CompareScenarioModal";
import CompareScenarioKPIsSection from "../components/compare-scenario/kpis-section/CompareScenarioKPIsSection";
import CompareScenarioResultsSection from "../components/compare-scenario/Results";
import { generateTableHeader } from "../components/compare-scenario/HeaderTable";
import { ManageCompareExport } from "../components/compare-scenario/export-side-panel/ManageCompareExport";
import { ViewMacroScenarioViewModel } from "../../../orval/generated/models";
import { OptionalMetricsTableResultsType } from "../components/optional-metrics-table/HelperService";

const redirectURL = MacroToolRoutesConfig.compareScenarioPage;

function CompareScenarioPage() {
  const { t } = useTranslation("macro");
  const navigate = useNavigate();
  const [tabList, setTabList] = useState<Tablist[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [scenarios, setScenarios] = useState<
    ViewMacroScenarioViewModel[] | null
  >(null);
  const [tableData, setTableData] = useState<any>(
    new Map([
      ["data", []],
      ["columns", []],
    ]),
  );
  const [searchParams] = useSearchParams();
  const scenarioIds = useMemo(
    () => searchParams.getAll("scenarioId"),
    [searchParams],
  );
  const { showGlobalLoader } = useGlobalLoader();

  useEffect(() => {
    if (scenarioIds.length > 0) {
      callGetScenarioComparisonAPI(scenarioIds);
    }
  }, [scenarioIds]);

  useEffect(() => {
    if (scenarioIds.length > 0 && scenarios) {
      const tData = generateTableHeader(scenarios);
      setTableData(tData);
    }
  }, [scenarioIds, scenarios]);

  function callGetScenarioComparisonAPI(scenarioIds: string[]) {
    showGlobalLoader(true);
    const scenariosToCompare: ViewMacroScenarioViewModel[] = [];
    scenarioIds.forEach((id) => {
      // Call the API for each scenario ID
      // API.getScenarioDetails(id)
      //   .then((res: ViewMacroScenarioViewModel) => {
      //     // Handle the response here
      //     scenariosToCompare.push(res);
      //   })
      //   .catch((error) => {
      //     // Handle the error here
      //     // setScenarioDetailsFailed(true);
      //     //     // showSnackbar(error.response.data.detail, "error");
      //   })
      //   .finally(() => {
      //     setScenarios(scenariosToCompare);
      //     showGlobalLoader(false);
      //   });
    });
  }

  function handleRemoveScenario(scenarioId: string) {
    const newScenarioIds = scenarioIds.filter((id) => id !== scenarioId);
    callGetScenarioComparisonAPI(newScenarioIds);
    const param = createSearchParams({
      scenarioId: newScenarioIds,
    });
    navigate(`${redirectURL}?${param}`);
  }

  return (
    <>
      <Box>
        <ManageCompareExport
          open={open}
          setOpen={setOpen}
          scenarios={scenarios}
        />

        <Modal
          width="lg"
          content={
            <CompareScenarioModal
              scenarios={scenarios}
              setEdit={setEdit}
              scenarioIds={scenarioIds}
            />
          }
          isOpen={edit}
          closeModal={() => setEdit(false)}
        ></Modal>
        <Button
          variant="text"
          onClick={() => navigate(MacroToolRoutesConfig.countryLevelAnalytics)}
          startIcon={<ArrowBackIosSharpIcon />}
        >
          {t("createScenarioSection.backToMacroHome")}
        </Button>
        <CompareScenarioHeroSection
          setEdit={setEdit}
          disabled={!scenarios}
          onExportScenario={() => setOpen(true)}
        />
        <Box mt={3}>
          <Box sx={{ position: "sticky", zIndex: 19, top: "64px" }}>
            <CompareScenarioHeader
              data={tableData.get("data")}
              columns={tableData.get("columns")}
              onRemoveScenario={handleRemoveScenario}
              isMoreThanTwoScenarios={scenarioIds.length > 2}
            />
          </Box>
          {/*TODO: business would like adjustments disabled for now*/}
          {/*<CompareScenarioAdjustments*/}
          {/*  disabled={!scenarios}*/}
          {/*  scenarios={scenarios}*/}
          {/*/>*/}
          <CompareScenarioResultsSection
            disabled={!scenarios}
            tabList={tabList}
            scenarios={scenarios}
            type={OptionalMetricsTableResultsType.BY_CATEGORY}
          />
          <CompareScenarioResultsSection
            type={OptionalMetricsTableResultsType.BY_PACK_TYPE}
            scenarios={scenarios}
            tabList={tabList}
            disabled={!scenarios}
          />
          <CompareScenarioKPIsSection
            scenarios={scenarios}
            disabled={!scenarios}
          />
        </Box>
      </Box>
    </>
  );
}

export default CompareScenarioPage;
