import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  SKUMinimalViewModel,
  ViewMicroScenario,
} from "../../../orval/generated/models";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { SortableOverlay } from "./SortableOverlay";
import { SortableItem } from "./SortableItem";

type SelectedProductsSectionProps = {
  selectedProducts: SKUMinimalViewModel[];
  changedProducts: SKUMinimalViewModel[];
  setSelectedProducts: (
    selectedProducts: (item: SKUMinimalViewModel[]) => SKUMinimalViewModel[],
  ) => void;
  handleRemoveSelectedProduct: (id: number) => void;
  handleClearAll: () => void;
  scenarioDetails: ViewMicroScenario;
};

interface ActiveEvent {
  id: number | string;
}

export function SelectedProductsSection({
  selectedProducts,
  changedProducts,
  setSelectedProducts,
  handleRemoveSelectedProduct,
  handleClearAll,
  scenarioDetails,
}: SelectedProductsSectionProps) {
  const { t } = useTranslation("micro");
  const boxRemHeight = 14;
  const [activeItem, setActiveItem] = useState<SKUMinimalViewModel | null>(
    null,
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event: { active: ActiveEvent }) {
    const { active } = event;
    const itemIndex = selectedProducts.findIndex(
      ({ id }) => active.id === id.toString(),
    );
    const item = selectedProducts[itemIndex];
    setActiveItem(item);
  }

  function handleDragOver(event: {
    active: ActiveEvent;
    over: ActiveEvent | null;
  }) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setSelectedProducts((x: SKUMinimalViewModel[]) => {
        const oldIndex = x.findIndex(({ id }) => active.id === id.toString());
        const newIndex = x.findIndex(({ id }) => over.id === id.toString());

        return arrayMove(selectedProducts, oldIndex, newIndex);
      });
    }
  }

  return (
    <Box>
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="bold">
          {t("productsSection.selectProductModal.selectedProducts")}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={handleClearAll}
          disabled={selectedProducts.length === 0}
          sx={{ marginLeft: 1 }}
        >
          {t("productsSection.selectProductModal.clearAllProducts")}
        </Button>
      </Box>
      <Box sx={{ height: "32em", overflow: "scroll" }}>
        {selectedProducts.length > 0 ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
          >
            <SortableContext
              items={selectedProducts}
              strategy={verticalListSortingStrategy}
            >
              {selectedProducts.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id.toString()}
                  item={item}
                  selectedProducts={selectedProducts}
                  handleRemoveSelectedProduct={handleRemoveSelectedProduct}
                  overlay={false}
                  scenarioProduct={
                    !changedProducts.some((product) => product.id === item.id)
                      ? scenarioDetails.products.find(
                        (x) => x.product_name === item.product_name,
                      )
                      : undefined
                  }
                />
              ))}
            </SortableContext>
            <SortableOverlay>
              {activeItem ? (
                <SortableItem
                  key={activeItem.id}
                  id={activeItem.id.toString()}
                  item={activeItem}
                  selectedProducts={selectedProducts}
                  handleRemoveSelectedProduct={handleRemoveSelectedProduct}
                  overlay={true}
                />
              ) : null}
            </SortableOverlay>
          </DndContext>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: `${boxRemHeight}rem`,
            }}
          >
            <Typography>
              {t("productsSection.selectProductModal.noProductSelected")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
