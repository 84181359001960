import Box from "@mui/material/Box";
import { AccordionComponent } from "../../common";
import { useTranslation } from "react-i18next";
import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import PillarDeepDiveTable from "../../pillar-deep-dive-table/PillarDeepDiveTable";
import { TableResultsFilterDataType, TableResultsType } from "./types";
import { CarbonEmissionsBySection } from "../../carbon-emissions-by-section/CarbonEmissionsBySection";
import { OptionalMetricsTableResultsType } from "../../optional-metrics-table/HelperService";

type ResultsSectionProps = {
  disabled: boolean;
  scenario: ViewMacroScenarioViewModel | null;
};

export function ResultsTabSection({ disabled, scenario }: ResultsSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsOverview = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_PACK_TYPE}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPillar",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_PILLAR}
        />
      ),
    },
  ];

  const subAccordionLabelsPillarDeepDive = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <PillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byCategory")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenario={scenario}
          tableType={TableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <PillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byPack")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenario={scenario}
          tableType={TableResultsType.BY_PACK_TYPE}
        />
      ),
    },
  ];

  return (
    <Box mb={10}>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.overview")}
        disabled={disabled}
      >
        {subAccordionLabelsOverview.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              <Box display="flex" gap="32px" justifyContent="space-between">
                {item.component}
              </Box>
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.pillarDeepDive")}
        disabled={disabled}
      >
        {subAccordionLabelsPillarDeepDive.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              <Box display="flex" gap="32px" justifyContent="space-between">
                {item.component}
              </Box>
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
