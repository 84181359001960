import { t } from "i18next";

export const defaultLanguage = "en";
export const fallbackLanguage = "en";
export const supportedLanguages = ["en"];
export const defaultNameSpace = "common";
export const NamespaceMappings = {
  common: "common",
  home: "home",
  dashboard: "dashboard",
  login: "login",
  micro: "micro",
  macro: "macro",
  productConfiguration: "productConfiguration",
  toolConfiguration: "toolConfiguration",
};
