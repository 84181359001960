import { useEffect, useState } from "react";
import { AuthenticationProvders } from "../../../constants/enums";
import { AppConstant } from "../../../constants";
import { IdamUser } from "../types";

export const useAuthUser = () => {
  const [userInfo, setUserInfo] = useState<IdamUser>();

  useEffect(() => {
    const userDataFromSessionStorage: any =
      sessionStorage.getItem("userInfo") || "";
    const appAuthProvider: any = AppConstant.authentication;

    switch (appAuthProvider?.provider) {
      case AuthenticationProvders.IDAM:
        return setUserInfo(JSON.parse(userDataFromSessionStorage || null));

      default:
        return setUserInfo(undefined);
    }
  }, []);

  return userInfo;
};
