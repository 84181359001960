import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { TableResultsFilterDataType } from "../results-tab-section";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./MekkoFilters.scss";
import FiltersContainer from "../../../../../components/common/filtersContainer/FiltersContainer";

type MekkoFiltersProps = {
  years: string[];
  categories: string[];
  units: TableResultsFilterDataType;
  handleSetMekkoYear: (year: string) => void;
  setSelectedMekkoCategories: (categories: string[]) => void;
  setSelectedUnits: (unit: TableResultsFilterDataType) => void;
};

export function MekkoFilters({
  years,
  categories,
  units,
  handleSetMekkoYear,
  setSelectedMekkoCategories,
  setSelectedUnits,
}: MekkoFiltersProps) {
  const { t } = useTranslation("macro");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(categories);

  useEffect(() => {
    if (categories.length > 0 && selectedCategory === "") {
      setSelectedCategory(categories[0]);
      setSelectedCategories(categories);
      handleCategoriesTypeChange(categories);
    }
    if (years.length > 0 && selectedYear === "") {
      setSelectedYear(years[0]);
    }
  }, [categories, selectedCategory, selectedYear, years]);

  const handleChangeUnits = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === TableResultsFilterDataType.ABSOLUTE) {
      setSelectedUnits(TableResultsFilterDataType.ABSOLUTE);
    } else {
      setSelectedUnits(TableResultsFilterDataType.PER_LITRE);
    }
  };

  const handleSelectedYear = (e: any) => {
    const value = e.target.value as string;
    setSelectedYear(value);
    if (handleSetMekkoYear) {
      handleSetMekkoYear(value);
    }
  };

  const handleCategoriesTypeChange = (newSelectedCategories: string[]) => {
    setSelectedCategories(newSelectedCategories);
    setSelectedMekkoCategories(newSelectedCategories);
  };

  return (
    <FiltersContainer
      leftFilters={
        <Box display="flex" gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <InputLabel htmlFor="year" id="year">
                  {t("mekkoAnalysis.labels.year")}
                </InputLabel>
                <Select
                  id="year"
                  labelId="year"
                  label={t("mekkoAnalysis.labels.year")}
                  onChange={handleSelectedYear}
                  value={selectedYear}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth disabled={true}>
                {/* TODO re enable once category filtering is available on the backend */}
                {/*<InputLabel htmlFor="category" id="category">*/}
                {/*  {t("mekkoAnalysis.labels.category")}*/}
                {/*</InputLabel>*/}
                {/*<Select*/}
                {/*  id="category"*/}
                {/*  labelId="category"*/}
                {/*  label={t("mekkoAnalysis.labels.category")}*/}
                {/*  multiple={true}*/}
                {/*  value={selectedCategories}*/}
                {/*  onChange={(e) => {*/}
                {/*    const newSelectedCategories = e.target.value as string[];*/}
                {/*    handleCategoriesTypeChange(newSelectedCategories);*/}
                {/*  }}*/}
                {/*  renderValue={(selected) => {*/}
                {/*    if (selected.length === 0) {*/}
                {/*      return null;*/}
                {/*    }*/}
                {/*    return selected.join(", ");*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {categories.sort().map((category) => (*/}
                {/*    <MenuItem key={category} value={category}>*/}
                {/*      {category}*/}
                {/*    </MenuItem>*/}
                {/*  ))}*/}
                {/*</Select>*/}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      }
    ></FiltersContainer>
  );
}
