import { createSlice } from "@reduxjs/toolkit";

type Year = {
  from: string;
  to: string;
};

type RootState = {
  years: Record<string, Year>;
};

const initialState: RootState = {
  years: {},
};

export const targetSlice = createSlice({
  name: "targetYear",
  initialState,
  reducers: {
    targetChangeYearValue(state, action) {
      const { type, value } = action.payload;
      state.years[type] = value;
    },
  },
});
