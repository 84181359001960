import { NamespaceMappings } from "./config";

export const namespaces = [
  NamespaceMappings.common,
  NamespaceMappings.home,
  NamespaceMappings.dashboard,
  NamespaceMappings.login,
  NamespaceMappings.micro,
  NamespaceMappings.macro,
  NamespaceMappings.productConfiguration,
  NamespaceMappings.toolConfiguration,
];
