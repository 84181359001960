/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Product Emissions Forecaster
 * OpenAPI spec version: 3.1
 */

export type OtherPackagingSubAccordionEnum =
  (typeof OtherPackagingSubAccordionEnum)[keyof typeof OtherPackagingSubAccordionEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OtherPackagingSubAccordionEnum = {
  Other_primary: "Other primary",
  Secondary: "Secondary",
  Tertiary: "Tertiary",
} as const;
