import { Box, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { timeChartData } from "../../types";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

type Props = {
  labels: string[];
  datasets: timeChartData;
};

Chart.register(ChartDataLabels);

function EmissionTimeChart({ labels, datasets }: Props) {
  const { palette } = useTheme();

  const data = {
    labels,
    datasets,
  } as const;

  const mainAxis = {
    ticks: {
      color: `${palette.textColor.main}`,
    },
    border: {
      color: `${palette.textColor.main}`,
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // for chart to take full width of screen
    scaleShowVerticalLines: false,
    datasets: {
      bar: {
        barThickness: 36,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove vertical grid lines
        },

        ...mainAxis,
      },
      y: {
        ...mainAxis,
        display: false,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        align: "end" as const,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          font: { size: 14 },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: "auto",
        color: "black",
        anchor: "end" as const,
        align: "end" as const,
        offset: -2 as const,
        formatter: function (value: any, context: any) {
          if (typeof value === "number" && !isNaN(value)) {
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            });
          } else {
            return value;
          }
        },
      },
    },
  };

  return (
    <Box sx={{ height: "528px" }}>
      <Bar options={options} data={data} />
    </Box>
  );
}

export default EmissionTimeChart;
