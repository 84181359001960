import { createSlice } from "@reduxjs/toolkit";

type heating = {
  id: number;
  name: string;
  type: string;
};
const initialState: heating[] = [];

export const heatingSlice = createSlice({
  name: "heating",
  initialState,
  reducers: {
    addHeating(state, action) {
      return [...state, action.payload];
    },
    removeHeating(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
