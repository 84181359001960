export enum ProductConfigurationsMicro {
  SERVING_SIZE = "serving_size",
  INGREDIENTS = "ingredients",
  INGREDIENTS_EMISSION_FACTORS = "ingredients_ef",
  BODY_AND_LID_PACKAGING = "body_and_lid_packaging",
  BODY_AND_LID_EMISSION_FACTORS = "body_and_lid_ef",
  OTHER_PACKAGING = "other_packaging",
  OTHER_PACKAGING_PRIMARY = "other_primary",
  OTHER_PACKAGING_SECONDARY = "secondary",
  OTHER_PACKAGING_TERTIARY = "tertiary",
  OTHER_PACKAGING_EMISSION_FACTORS = "other_packaging_ef",
  MANUFACTURING = "manufacturing",
  LOGISTICS = "logistics",
  RESULTS = "results",
}

export enum ProductConfigurationsMacro {
  ABSOLUTE = "absolute",
  PER_LITRE = "per_litre",
  PERCENTAGE = "percentage",
  SALES = "sales",
  MEKKO = "mekko",
}

export type ProductsEditableColumnConfigItem = {
  field_id?: number;
  field: string;
  label: string;
  defaultUnit: MicroUnits.OVERRIDE | MicroUnits.PERCENTAGE;
};

export type DataStructureKey =
  (typeof ProductConfigurationsMicro)[keyof typeof ProductConfigurationsMicro];

export enum MicroUnits {
  PERCENTAGE = "percentage",
  OVERRIDE = "",
}

export const MicroUnitsTranslations = {
  [MicroUnits.PERCENTAGE]: "%",
  [MicroUnits.OVERRIDE]: "",
} as const;

export type rowValidationType = {
  metric: string;
  type: "percentage" | "unit";
  isNonZero: boolean;
};

export type rowValidationConfig = {
  rows: rowValidationType[];
};

export type SubRow = {
  id: string;
  metric: string;
  validationType: string;
  validationIsNonZero: boolean;
  [key: string]: string | boolean;
};

export type TableRow = {
  metric: string;
  dataStructureKey: string;
  subRows: SubRow[];
  [key: string]: string | number | SubRow[];
};

export type TableData = TableRow[];
