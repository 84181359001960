import i18next from "i18next";
import { ProductMetrics } from "./TableConfig";
import { ProductColumnData } from "../../types";
import { ProductMetricsType, ProductTableData } from "../ProdutsTable/types";
import { Product } from "../../../../orval/generated/models";

function getProductsTableData(
  products: ProductColumnData[],
  items: Product[],
  stringify?: boolean,
): ProductTableData {
  const data: Array<any> = [];

  ProductMetrics.forEach((config: ProductMetricsType) => {
    const dataObj: any = {
      metric: i18next.t(config.label),
    };

    products.forEach((product: ProductColumnData) => {
      const productData = items.find((pr) => pr.guid === product.guid);
      dataObj[`Product ${productData?.guid}` || ""] =
        stringify && productData?.[config.key as keyof Product]
          ? `${productData[config.key as keyof Product]} ${config.key === "pack_size" ? " ml" : ""}`
          : `${productData?.[config.key as keyof Product]}${config.key === "pack_size" ? " ml" : ""}`;
    });
    data.push(dataObj);
  });
  return data;
}

export { getProductsTableData };
