import { TextField, Box, Chip } from "@mui/material";
import { useState } from "react";

interface TextEditProps {
  value?: string;
  onSaveInput: Function;
  chipLabel?: string;
  className?: string;
  testId?: string;
}

export const TextEdit = ({
  value,
  onSaveInput,
  chipLabel,
  className,
  testId = "text-input",
}: TextEditProps) => {
  const [textValue, setTextValue] = useState<string | undefined>(value);
  const valueWidth =
    (textValue?.length ?? 0) < 9 ? 150 : (textValue?.length ?? 0) * 11 + 50;

  const onTextInputChange = (event: any) => {
    setTextValue(event?.target?.value);
  };

  const onBlur = () => {
    onSaveInput(textValue);
  };

  return (
    <Box
      className="text-edit"
      display={"flex"}
      alignItems={"center"}
      width={valueWidth}
      maxWidth={valueWidth}
    >
      <TextField
        value={textValue}
        data-testid={testId}
        variant="outlined"
        onChange={onTextInputChange}
        onBlur={onBlur}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onTextInputChange((e.target as HTMLInputElement).value);
            onBlur();
          }
        }}
        focused
        autoFocus
        className={className}
        fullWidth
      />
      {chipLabel && <Chip sx={{ ml: 2 }} label={chipLabel} />}
    </Box>
  );
};
