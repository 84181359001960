import { createSlice } from "@reduxjs/toolkit";

type ingredients = {
  id: number;
  name: string;
  type: string;
};
const initialState: ingredients[] = [];

export const ingredientsSlice = createSlice({
  name: "ingredient",
  initialState,
  reducers: {
    addIngredient(state, action) {
      return [...state, action.payload];
    },
    removeIngredient(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
