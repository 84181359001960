import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmissionChartSection from "../../emission-chart/EmissionChartSection";
import { CustomTabs, Tablist } from "../../../../../components";
import { useEffect, useState } from "react";

import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";
import { OptionalMetricsTableResultsType } from "../../optional-metrics-table/HelperService";

type Props = {
  tabList: Tablist[];
  type: OptionalMetricsTableResultsType;
  scenarios: ViewMacroScenarioViewModel[] | null;
};

export function Movement({ tabList, type, scenarios }: Props) {
  const { t } = useTranslation("macro");
  const [scenario, setSenario] = useState<ViewMacroScenarioViewModel | null>(
    null,
  );
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (scenarios) {
      setSenario(scenarios[value]);
    }
  }, [value, scenarios]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="header-H4">
          {t("movementSection.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTabs
          removeTranslate={true}
          tabList={tabList}
          value={value}
          setValue={setValue}
        />

        <Box sx={{ marginTop: "28px" }}>
          {/*<EmissionChartSection*/}
          {/*  value={type === "category" ? 0 : 1}*/}
          {/*  scenario={scenario}*/}
          {/*/>*/}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
