import {
  IngredientViewModel,
  MaterialViewModel,
  SKUAdjustmentAccordionDetails,
} from "../../../../orval/generated/models";
import {
  MicroUnits,
  ProductConfigurationsMicro,
  ProductsEditableColumnConfigItem,
} from "../ProductsEditableTable";

export const generateEmissionFactorAdjustments = (
  adjustments: string[],
): string[] => {
  return adjustments.map((a) => {
    return a
      .replace("weight (g/litre)", "virgin EF (g CO₂e/g material)")
      .replace("recycled content (%)", "recycled EF (g CO₂e/g material)");
  });
};

export function generateTableMetric(
  accordion: SKUAdjustmentAccordionDetails,
  filteredMetricsData: string[] | undefined,
  allMetricsData: IngredientViewModel[] | MaterialViewModel[],
  t: (key: string) => string,
): ProductsEditableColumnConfigItem[] {
  switch (accordion.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field: field.field,
          label: t("micro:servingSizeSection.table.servingSize"),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.MANUFACTURING:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field: field.field,
          label: t(`micro:manufacturingSection.table.${field.field}Eur`),
          defaultUnit: MicroUnits.PERCENTAGE,
        })) || []
      );

    case ProductConfigurationsMicro.LOGISTICS:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field: field.field,
          label: t(`micro:logisticsSection.table.${field.field}`),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field: field.field,
          label: t(`micro:packagingSection.table.${field.field}`),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return (
        accordion.fields?.map((field) => ({
          field_id: field.field_id,
          field: field.field,
          label: t(
            `micro:bodyAndLidEmissionFactorsSection.table.${field.field}`,
          ),
          defaultUnit: MicroUnits.OVERRIDE,
        })) || []
      );

    case ProductConfigurationsMicro.INGREDIENTS:
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
      return (
        filteredMetricsData?.map((ingredient) => {
          const ingredientObject = (
            allMetricsData as IngredientViewModel[]
          ).find(
            (ing) => (ing as IngredientViewModel).ingredient === ingredient,
          );
          return {
            field_id: ingredientObject?.field_id,
            field: (ingredientObject as IngredientViewModel).ingredient,
            label: `${(ingredientObject as IngredientViewModel).ingredient} ${accordion.accordion === ProductConfigurationsMicro.INGREDIENTS ? "per litre (g/litre)" : "EF (g CO₂e/g ingredient)"}`,
            defaultUnit: MicroUnits.PERCENTAGE,
          };
        }) || []
      );

    default:
      if (filteredMetricsData) {
        return (
          filteredMetricsData?.map((material) => {
            let materialObject = (allMetricsData as MaterialViewModel[]).find(
              (mt) => (mt as MaterialViewModel).material === material,
            );
            return {
              field_id: (materialObject as MaterialViewModel).field_id,
              field: (materialObject as MaterialViewModel).material,
              label: t(
                `common:${(materialObject as MaterialViewModel).material}`,
              ),
              defaultUnit: MicroUnits.PERCENTAGE,
            };
          }) || []
        );
      } else {
        return [];
      }
  }
}
