import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./TableDataTypesAccordion.scss";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";

interface TableDataTypesAccordionProps {
  id: string;
  accordionHeader: string;
  accordionContent: string[];
  value: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLButtonElement>) => void;
  error: boolean;
  reset: boolean;
}

export default function TableDataTypesAccordion(
  props: TableDataTypesAccordionProps,
) {
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.reset) {
      setChecked(false);
    }
  }, [props.reset]);

  const handleAccordionExpansionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    if (!(event.target as HTMLElement).closest(".MuiCheckbox-root")) {
      setExpanded(isExpanded);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    props.onChange(event);
    setChecked(event.target.checked);
  };

  return (
    <Accordion
      className={`dataExtractionAccordion ${checked ? "checkedStyle" : "defaultStyle"}`}
      expanded={expanded}
      onChange={handleAccordionExpansionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
        className={`dataExtractionAccordionSummary ${expanded ? "expanded" : ""}`}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            onBlur={props.onBlur}
            id={props.id}
            name="tables"
          />
          <Typography sx={{ fontWeight: "500" }}>
            {props.accordionHeader}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={"dataExtractionAccordionDetails"}>
        {props.accordionContent.map((content, index) => (
          <Typography key={index}>{content}</Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
