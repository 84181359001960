import { useState } from "react";

import xlsx from "json-as-xlsx";

import { ManageExportPanel } from "../../../../../components/common/manage-export/ManageExportPanel";
import { getExportData } from "./HelperService";
import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";

type Props = {
  scenarios: ViewMacroScenarioViewModel[] | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export function ManageCompareExport({ scenarios, open, setOpen }: Props) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const options = [
    "allAdjustments",
    "overview",
    "absoulteEmission",
    "perLitreEmission",
    "movementEmission",
    "timeEvolution",
    "kpis",
  ];
  const optionValues = [
    {
      label: "overview",
      keys: ["base", "glide", "scenario"],
      value: [
        {
          sheet: "overview_categ_absol",
          path: "results.category.overview.absolute",
        },
        {
          sheet: "overview_categ_per_litre",
          path: "results.category.overview.per_litre",
        },

        {
          sheet: "overview_byPack_absol",
          path: "results.by_pack.overview.absolute",
        },
        {
          sheet: "overview_byPack_per_litre",
          path: "results.by_pack.overview.per_litre",
        },
      ],
    },
    {
      keys: [],
      label: "absoulteEmission",
      value: [
        {
          sheet: "absol_by_pillar_categ_",
          path: "results.category.absolute_by_pillar",
        },
        {
          sheet: "absol_by_pillar_byPack_",
          path: "results.by_pack.absolute_by_pillar",
        },
      ],
    },
    {
      keys: [],
      label: "perLitreEmission",
      value: [
        {
          sheet: "pl_by_pillar_categ_",
          path: "results.category.per_litre_by_pillar",
        },
        {
          sheet: "pl_by_pillar_byPack_",
          path: "results.by_pack.per_litre_by_pillar",
        },
      ],
    },
    {
      label: "movementEmission",
      keys: [],
      value: [
        {
          sheet: "movement_base_glide_categ",
          path: "results.movement.by_category",
          keyName: "base_to_glide",
        },
        {
          sheet: "movement_base_glide_byPack",
          path: "results.movement.by_pack_type",
          keyName: "base_to_glide",
        },
      ],
    },
    {
      label: "timeEvolution",
      keys: ["base_to_glide_change"],
      value: [
        {
          sheet: "time_categ_per_litre",
          path: "results.category.overview.per_litre",
        },
        {
          sheet: "time_categ_absol",
          path: "results.category.overview.absolute",
        },
        {
          sheet: "time_byPack_per_litre",
          path: "results.by_pack.overview.per_litre",
        },
        {
          sheet: "time_byPack_absol",
          path: "results.by_pack.overview.absolute",
        },
      ],
    },

    {
      label: "allAdjustments",
      keys: [],
      value: [
        {
          sheet: "pack_size_adjustments",
          source: "pack-size",
        },
        {
          sheet: "ingredients_adjustments",
          source: "ingredients",
        },
        {
          sheet: "packaging_adjustments",
          source: "packaging",
        },
        {
          sheet: "manufacturing_adjustments",
          source: "manufacturing",
        },
        {
          sheet: "logistics_adjustments",
          source: "logistics",
        },
      ],
    },
    {
      label: "kpis",
      keys: [],
      value: [
        {
          sheet: "kpis_proportion_of_volume",
          path: "results.kpi.proportion_of_volume",
        },
        {
          sheet: "kpis_reuse_rate",
          path: "results.kpi.reuse_rate",
        },
        {
          sheet: "kpis_total_weight_packaging",
          path: "results.kpi.total_weight_packaging",
        },
        {
          sheet: "kpis_total_share_dlz",
          path: "results.kpi.total_share_dlz",
        },
        {
          sheet: "kpis_total_weight_sugar",
          path: "results.kpi.total_weight_sugar",
        },
        {
          sheet: "kpis_average_sugar_per_litre",
          path: "results.kpi.average_sugar_content_per_litre",
        },
        {
          sheet: "kpis_total_energy_usage_ratio",
          path: "results.kpi.total_energy_usage_ratio",
        },
        {
          sheet: "kpis_specific_eur",
          path: "results.kpi.specific_eur",
        },
        {
          sheet: "kpis_energy_source",
          path: "results.kpi.electricity_source",
        },
        {
          sheet: "kpis_heat_source",
          path: "results.kpi.heat_source",
        },
        {
          sheet: "kpis_fuel_type_distribution",
          path: "results.kpi.fuel_type_distribution",
        },
      ],
    },
  ];

  function handleCsv() {
    if (scenarios) {
      let settings = {
        fileName: "ScenarioComparision",
        extraLength: 3,
      };

      xlsx(getExportData(selectedOptions, optionValues, scenarios), settings);
      setOpen(false);
      setSelectedOptions([]);
    }
  }

  return (
    <>
      <ManageExportPanel
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions}
        options={options}
        open={open}
        translationKey="macro"
        setOpen={setOpen}
        handleCsv={handleCsv}
      />
    </>
  );
}
