import { AppConstant } from "../constants";
import {
  ProductConfigurationsMacro,
  ProductConfigurationsMicro,
} from "../features";

export function formatNumber(
  value: number | null | undefined | string,
  dataStructureKey?: string,
  parentAccordion?: string,
): string {
  if (value === null || value === undefined || value === "") {
    return AppConstant.emptyCell; // Return null if the value is null or undefined
  }

  dataStructureKey = parentAccordion ?? dataStructureKey;

  let numberToFormat: number;
  if (typeof value === "string") {
    numberToFormat = parseFloat(value);
  } else {
    numberToFormat = value;
  }

  let decimalPlaces: number;
  switch (dataStructureKey) {
    case ProductConfigurationsMicro.SERVING_SIZE:
    case ProductConfigurationsMacro.ABSOLUTE:
      decimalPlaces = 0;
      break;
    case ProductConfigurationsMicro.INGREDIENTS:
    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
    case ProductConfigurationsMicro.LOGISTICS:
    case ProductConfigurationsMicro.OTHER_PACKAGING:
    case ProductConfigurationsMacro.PER_LITRE:
    case ProductConfigurationsMacro.PERCENTAGE:
    case ProductConfigurationsMacro.SALES:
    case ProductConfigurationsMacro.MEKKO:
    case ProductConfigurationsMicro.RESULTS:
      decimalPlaces = 1;
      break;
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
    case ProductConfigurationsMicro.MANUFACTURING:
    case ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS:
      decimalPlaces = 3;
      break;
    default:
      const valueString = numberToFormat.toString();
      const decimalIndex = valueString.indexOf(".");
      decimalPlaces =
        decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;
  }

  const locale = navigator.language || "en-US"; // Detect the locale from the browser

  return numberToFormat.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function formatNumberOrEmpty(
  value: number | null | undefined | string,
  dataStructureKey?: string,
): string {
  return value !== 0
    ? formatNumber(value, dataStructureKey)
    : AppConstant.emptyCell;
}
