import { Box } from "@mui/material";
import "./NivoColours.css";

interface Props {
  keys: string[];
  chartColours: string[];
}

export const CustomLegend = ({ keys, chartColours }: Props) => {
  function formatKey(key: string) {
    key = key.charAt(0).toUpperCase() + key.slice(1);
    return key.replaceAll("_", " ");
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {keys.map((chartKey, index) => {
        return (
          <Box
            key={index}
            p={1}
            sx={{
              borderRadius: 1,
              flexShrink: 0,
              fontFamily: "sans-serif",
              fontSize: "12px",
              fill: "rgb(51, 51, 51)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                gap: "5px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  backgroundColor: chartColours[index],
                }}
              ></Box>
              <span>{formatKey(chartKey)}</span>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
