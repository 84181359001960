import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IngredientsSection,
  ManufacturingSection,
  PackSizeSection,
  PackagingSection,
  LogisticsSection,
} from "./adjustments-section";
import { generateAdjustmentsData } from "./adjustments-section/HelperService";
import { AdjustmentSections } from "./adjustments-section/HelperService";
import {ViewMacroScenarioViewModel} from "../../../../orval/generated/models";

type AdjustmentsProps = {
  disabled: boolean;
  scenarios: ViewMacroScenarioViewModel[] | null;
};
function CompareScenarioAdjustments({ disabled, scenarios }: AdjustmentsProps) {
  const { t } = useTranslation("macro");
  const [tableData, setTableData] = useState<any>();
  useEffect(() => {
    if (scenarios && scenarios.length > 0) {
      const tData = generateAdjustmentsData(scenarios);
      setTableData(tData);
    }
  }, [scenarios]);

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary className="no-border" expandIcon={<ExpandMoreIcon />}>
        <Typography className="header-H4-bold">
          {t("compareScenarioSection.headersSection.adjustments.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PackSizeSection
          disabled={disabled}
          columns={tableData?.get(AdjustmentSections.PackSize).columns || []}
          data={tableData?.get(AdjustmentSections.PackSize).data || []}
        />
        <IngredientsSection
          disabled={disabled}
          columns={tableData?.get(AdjustmentSections.Ingredients).columns || []}
          data={tableData?.get(AdjustmentSections.Ingredients).data || []}
        />
        <PackagingSection
          disabled={disabled}
          columns={tableData?.get(AdjustmentSections.Packaging).columns || []}
          data={tableData?.get(AdjustmentSections.Packaging).data || []}
        />
        <ManufacturingSection
          disabled={disabled}
          columns={
            tableData?.get(AdjustmentSections.Manufacturing).columns || []
          }
          data={tableData?.get(AdjustmentSections.Manufacturing).data || []}
        />
        <LogisticsSection
          disabled={disabled}
          columns={tableData?.get(AdjustmentSections.Logistics).columns || []}
          data={tableData?.get(AdjustmentSections.Logistics).data || []}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default CompareScenarioAdjustments;
