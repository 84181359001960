import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./core/navigation/routes";
import { GlobalLoaderProvider, NotificationProvider } from "./components";
import { ModalProvider } from "./components/common/modal";
import { TermsProvider } from "./features/terms-and-conditions/components/TermsProvider";
import "./App.scss";
import "../src/core";
import { MetadataProvider } from "./features/tool-configuration/components/BannerAlertConfigurationPage/MetadataContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MemberInfoProvider } from "./core/MemberInfoProvider/MemberInfoProvider";
import { appTheme } from "./core/theme/theme";

// Do NOT define inside App component without good reason - causes unstable query client.
const queryClient = new QueryClient();

function App() {
  // The useRoutes() hook allows you to define your routes as JavaScript objects
  // instead of <Routes> and <Route> elements. This is really just a style
  // preference for those who prefer to not use JSX for their routes config.
  let element = useRoutes(AppRoutes);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={appTheme}>
        <GlobalLoaderProvider>
          <NotificationProvider>
            <TermsProvider>
              <MetadataProvider>
                <ModalProvider>
                  <MemberInfoProvider>
                    <div className="App">{element}</div>
                  </MemberInfoProvider>
                </ModalProvider>
              </MetadataProvider>
            </TermsProvider>
          </NotificationProvider>
        </GlobalLoaderProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
