import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OptionalMetricsTable } from "../optional-metrics-table/OptionalMetricsTable";
import {
  OptionalMetricsTableResultsType,
  getResultsOverviewTableColumns,
  getResultsOverviewTableData,
  getResultsOverviewSalesTableData,
} from "../optional-metrics-table/HelperService";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";

type CarbonEmissionsBySectionProps = {
  scenario: ViewMacroScenarioViewModel | null;
  type: OptionalMetricsTableResultsType;
};

export function CarbonEmissionsBySection({
  scenario,
  type,
}: CarbonEmissionsBySectionProps) {
  const { t } = useTranslation("macro");
  const tempColumns = getResultsOverviewTableColumns(
    type,
    scenario?.inputs?.years?.base_year?.toString() || "",
    scenario?.inputs?.years?.target_year?.toString() || "",
  );

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid
          item
          xs={12}
          lg={type !== OptionalMetricsTableResultsType.BY_PILLAR ? 6 : 12}
        >
          <OptionalMetricsTable
            columns={tempColumns}
            data={scenario}
            dataExtractionFunction={getResultsOverviewTableData}
            type={type}
            displaySubtitle={true}
            title={t(
              "resultsSection.carbonEmissionOverview.tableHeaders.absoluteEmission",
            )}
            showFilterDropdown
          />
        </Grid>
        {type !== OptionalMetricsTableResultsType.BY_PILLAR && (
          <Grid item xs={12} lg={6}>
            <OptionalMetricsTable
              columns={tempColumns}
              data={scenario}
              dataExtractionFunction={getResultsOverviewSalesTableData}
              type={type}
              subtitle={t(
                "resultsSection.carbonEmissionOverview.filters.units.sales_volume",
              )}
              displaySubtitle={true}
              title={t(
                "resultsSection.carbonEmissionOverview.tableHeaders.salesVolume",
              )}
              showFilterDropdown={false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
