import i18next from "i18next";
import {
  EmissionForEachYear,
  EmissionPerPillar,
  ViewMacroScenarioViewModel,
  Years,
} from "../../../../orval/generated/models";
import {
  CombinedDataItem,
  pillarDeepDiveAccessorKeys,
  TableResultsFilterDataAccessorKey,
  TableResultsType,
} from "../scenario-results/results-tab-section";
import { formatNumberOrEmpty } from "../../../../utils/formatNumber";

function getPillarDeepDiveTableColumns() {
  let columns: {
    accessorKey: pillarDeepDiveAccessorKeys | string;
    header: string;
    id: pillarDeepDiveAccessorKeys | string;
    Cell?: (row: CombinedDataItem) => string | number | undefined;
  }[] = Object.values(pillarDeepDiveAccessorKeys).map((key) => {
    return {
      accessorKey: key,
      header: i18next.t(`common:emissionCategories.${key}`),
      id: key,
    };
  });

  // Create a single column for 'base', 'glide', and 'scenario'
  const baseOrGlideOrScenarioColumn = {
    accessorKey: "baseGlideScenario",
    header: "",
    id: "baseGlideScenario",
  };

  const categoryColumn = {
    accessorKey: "category",
    header: "",
    id: "category",
  };

  // Add the new column at the beginning of the columns array
  columns.unshift(baseOrGlideOrScenarioColumn);
  columns.unshift(categoryColumn);

  return columns;
}

function getResultsOverviewTableData(
  type: TableResultsType,
  scenario: ViewMacroScenarioViewModel,
  absoluteOrPerLitre: TableResultsFilterDataAccessorKey,
): CombinedDataItem[] {
  // By pillar does not contain the absoluteOrPerLitre keys
  if (type === TableResultsType.BY_PILLAR) {
    return [];
  }

  const dataObject = scenario?.results?.[type];

  if (!dataObject) {
    return [];
  }

  const filteredDataObject = Object.entries(dataObject).map(([key, value]) => {
    return {
      [key]: value[absoluteOrPerLitre],
    };
  });

  const combinedDataItems: CombinedDataItem[] = [];

  filteredDataObject.forEach((item: { [p: string]: EmissionPerPillar }) => {
    const key = Object.keys(item)[0];
    const categoryData = item[key] as EmissionPerPillar;

    ["base", "glide", "scenario"].forEach((type) => {
      const yearType =
        type === "base" ? "base" : type === "scenario" ? "target" : "";
      combinedDataItems.push({
        category: key,
        [type]: scenario.inputs
          ? (scenario.inputs.years as Years)?.[
              `${yearType}_year` as keyof Years
            ]
          : "",
        ingredients: formatNumberOrEmpty(
          categoryData.ingredients[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        packaging: formatNumberOrEmpty(
          categoryData.packaging[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        manufacturing: formatNumberOrEmpty(
          categoryData.manufacturing[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        logistics: formatNumberOrEmpty(
          categoryData.logistics[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        cde: formatNumberOrEmpty(
          categoryData.cde[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
        total: formatNumberOrEmpty(
          categoryData.total[type as keyof EmissionForEachYear],
          absoluteOrPerLitre,
        ),
      });
    });
  });

  return combinedDataItems;
}

export { getPillarDeepDiveTableColumns, getResultsOverviewTableData };
