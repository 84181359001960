import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";

import { getKPITableDataFromScenarios } from "./HelperService";
import { MacroTableWrapper } from "../../../../../components";

import "../../common-table/style.scss";
import {ViewMacroScenarioViewModel} from "../../../../../orval/generated/models";

type Props = {
  scenarios: ViewMacroScenarioViewModel[];
  kpiKey: string;
};

export default function CompareKPIsTable({ scenarios, kpiKey }: Props) {
  const { data } = getKPITableDataFromScenarios(scenarios, kpiKey);
  const { columns } = getKPITableDataFromScenarios(scenarios, kpiKey);

  const table = useMaterialReactTable({
    columns,
    data,
    enablePagination: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enableBottomToolbar: false,

    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        border: "none",
        borderBottom: "1px solid #cbcbcb",
        borderRight:
          (column.id.includes("scenario") ||
            column.id.includes("base") ||
            column.id === "unit") &&
          column.id !== columns[columns.length - 1].id
            ? "1px solid #cbcbcb"
            : "none",
      },
    }),
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        borderRight:
          (column.id.includes("scenario") ||
            column.id.includes("base") ||
            column.id === "unit") &&
          column.id !== columns[columns.length - 1].id
            ? "1px solid #cbcbcb"
            : "none",
      },
    }),
    initialState: {
      columnOrder: ["pack_type", "unit"],
    },
  });

  return (
    <Box className="displayHeader">
      <MacroTableWrapper>
        <MaterialReactTable table={table} />
      </MacroTableWrapper>
    </Box>
  );
}
