import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken, getBaseURL } from "../../../core/http/http-utils";
import { EnvironmentVariable } from "../../../constants";

const { localEnvConfig } = EnvironmentVariable;

export const adjustmentTypesApi = createApi({
  reducerPath: "adjustmentTypesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseURL(localEnvConfig.baseAPIURL),
    prepareHeaders: (headers, { getState }) => {
      const token = getAccessToken();

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBrands: builder.query<void, void>({
      query: () => "brands",
    }),
    getCategories: builder.query<void, void>({
      query: () => "categories",
    }),
    getPackTypes: builder.query<void, void>({
      query: () => "pack-types",
    }),
    getDLZTags: builder.query<void, void>({
      query: () => "dlz-tags",
    }),
    getCDETags: builder.query<void, void>({
      query: () => "cde-tags",
    }),
    getSizeGroupings: builder.query<void, void>({
      query: () => "size-groupings",
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetCategoriesQuery,
  useGetPackTypesQuery,
  useGetDLZTagsQuery,
  useGetCDETagsQuery,
  useGetSizeGroupingsQuery,
} = adjustmentTypesApi;
