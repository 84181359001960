import {
  MacroToolRoutesConfig,
  MicroToolRoutesConfig,
  ToolConfigurationRoutesConfig,
} from "../../features";
import { DataManagementRoutesConfig } from "../../features/data-management/navigation";

export enum Permission {
  ProductLevelAnalytics = "SKU/Product Level Analytics",
  CountryLevelAnalytics = "Country Level Analytics",
  DataManagement = "Data Management",
  Notifications = "Notifications",
  UserManagement = "User Management",
  ToolConfiguration = "Tool Configuration",
}

export const permissionRouteMap: { [key: string]: Permission } = {
  "/product-level-analytics": Permission.ProductLevelAnalytics,
  "/country-level-analytics": Permission.CountryLevelAnalytics,
  "/data-management": Permission.DataManagement,
  "/tool-configuration/system-alerts": Permission.Notifications,
  "/tool-configuration/user-management": Permission.UserManagement,
};
