import { createSlice } from "@reduxjs/toolkit";

type primaryPack = {
  id: string;
  bodyTab: string;
  lidTab: string;
  name: string;
  body: string;
  lid: string;
};
const initialState: primaryPack[] = [];

export const primaryPackSlice = createSlice({
  name: "primaryPack",
  initialState,
  reducers: {
    addPrimaryPack(state, action) {
      return [...state, action.payload];
    },
    removePrimaryPack(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
