import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import { realSlice } from "./slices/other/realSlice";
import { targetSlice } from "./slices/other/targetSlice";
import { ingredientsSlice } from "./slices/ingredientsSlice";
import { heatingSlice } from "./slices/heating-cooling/heatingSlice";
import { unitSlice } from "./slices/heating-cooling/unitSlice";
import { primaryPackSlice } from "./slices/packs/primaryPackSlice";
import { secondaryPackSlice } from "./slices/packs/secondaryPackSlice";
import { tertiaryPackSlice } from "./slices/packs/tertiaryPackSlice";
import { canPackSlice } from "./slices/packs/canPackSlice";
import { dispensedPackSlice } from "./slices/packs/dispensedPackSlice";
import { refGlassPackSlice } from "./slices/packs/refGlassPackSlice";
import { suppliersSlice } from "./slices/suppliersSlice";
import { countrySlice } from "./slices/other/countrySlice";
import { brandsSlice } from "./slices/brandsSlice";
import { percentChangeSlice } from "./slices/other/percentChangeSlice";
import { productCategoriesSlice } from "./slices/product-categories/productCategoriesSlice";
import { vehiclesSlice } from "./slices/vehicles/vehiclesSlice";
import { fuelsSlice } from "./slices/fuelsSlice/fuelsSlice";
import { adjustmentTypesApi } from "../../macro-tool/slices/apiSlice";

const rootReducer = combineReducers({});
export type IRootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: {
    productCategories: productCategoriesSlice.reducer,
    realYears: realSlice.reducer,
    targetYears: targetSlice.reducer,
    ingredients: ingredientsSlice.reducer,
    heatings: heatingSlice.reducer,
    vehicles: vehiclesSlice.reducer,
    fuels: fuelsSlice.reducer,
    units: unitSlice.reducer,
    primaryPacks: primaryPackSlice.reducer,
    secondaryPacks: secondaryPackSlice.reducer,
    tertiaryPacks: tertiaryPackSlice.reducer,
    canPacks: canPackSlice.reducer,
    dispensedPacks: dispensedPackSlice.reducer,
    refGlassPacks: refGlassPackSlice.reducer,
    brands: brandsSlice.reducer,
    suppliers: suppliersSlice.reducer,
    countries: countrySlice.reducer,
    percentages: percentChangeSlice.reducer,
    adjustmentTypesApi: adjustmentTypesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adjustmentTypesApi.middleware),
});

export { store };
export const {
  addProductCategory,
  removeProductCategory,
  setInitialProductCategories,
} = productCategoriesSlice.actions;
export const { changeYearValue } = realSlice.actions;
export const { targetChangeYearValue } = targetSlice.actions;
export const { addIngredient, removeIngredient } = ingredientsSlice.actions;
export const { addVehicle, removeVehicle, setInitialVehicles } =
  vehiclesSlice.actions;
export const { addFuel, removeFuel, setInitialFuels } = fuelsSlice.actions;
export const { addHeating, removeHeating } = heatingSlice.actions;
export const { addUnit, removeUnit } = unitSlice.actions;
export const { addPrimaryPack, removePrimaryPack } = primaryPackSlice.actions;
export const { addSecondaryPack, removeSecondaryPack } =
  secondaryPackSlice.actions;
export const { addTertiaryPack, removeterTiaryPack } =
  tertiaryPackSlice.actions;
export const { addCanPack, removeCanPack } = canPackSlice.actions;
export const { addDispensedPackS, removeDispensedPackS } =
  dispensedPackSlice.actions;
export const { addRefGlassPack, removeRefGlassPack } =
  refGlassPackSlice.actions;
export const { addBrand, removeBrand } = brandsSlice.actions;
export const { addSupplier } = suppliersSlice.actions;
export const { selectedCountry } = countrySlice.actions;
export const { addPercentage } = percentChangeSlice.actions;
