import { RouteObject } from "react-router-dom";
import HomePage from "../routes/HomePage";
import { RootPage } from "../../../components/sections/rootpage/RootPage";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { HomeRoutesConfig } from "./config";

export const HomeRoutes: RouteObject = {
  path: HomeRoutesConfig.homePage,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [{ index: true, element: <HomePage /> }],
};
