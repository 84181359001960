import { RouteObject } from "react-router-dom";
import { RootPage } from "../../../components/sections/rootpage/RootPage";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { ToolConfigurationRoutesConfig } from "./config";
import { ToolConfigurationPage } from "../routes/ToolConfigurationPage";
import BannerAlertConfigurationPage from "../components/BannerAlertConfigurationPage/BannerAlertConfigurationPage";
import UserManagementPage from "../components/UserManagementPage/UserManagementPage";

export const ToolConfigurationRoutes: RouteObject = {
  path: ToolConfigurationRoutesConfig.toolConfigurationPage,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <ToolConfigurationPage /> },
    {
      path: ToolConfigurationRoutesConfig.alertConfigurationPage,
      element: (
        <ProtectedRoutes>
          <BannerAlertConfigurationPage />
        </ProtectedRoutes>
      ),
    },
    {
      path: ToolConfigurationRoutesConfig.userManagementPage,
      element: (
        <ProtectedRoutes>
          <UserManagementPage />
        </ProtectedRoutes>
      ),
    },
  ],
};
