import i18next from "i18next";
import { formatText } from "../../../../../utils/formatText";
import { formatNumber } from "../../../../../utils/formatNumber";
import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";

function getExportData(
  selectedOptions: string[],
  optionValues: [] | { label: string; keys: string[]; value: object[] }[],
  scenarios: ViewMacroScenarioViewModel[],
) {
  //Extract an array of selected option objects
  const exportObjects = optionValues.filter((obj) =>
    selectedOptions.includes(obj.label),
  );
  //Map on each option object that needs to be exported
  const CsvSheets = exportObjects.map(
    ({
      label,
      keys,
      value,
    }: {
      label: String;
      keys: string[];
      value: any[];
    }) => {
      switch (label) {
        case "absoulteEmission":
        case "perLitreEmission":
          return restructureByKey(scenarios, value);
        case "movementEmission":
          return restructureNested(value, keys, scenarios);
        case "overview":
          return restructureOverview(value, scenarios);
        case "kpis":
          return restructureKpis(value, scenarios);
        // case "allAdjustments":
        //   return restructureAdjustments(value, keys, scenarios);
        default:
          return restructureByObject(value, keys, scenarios);
      }
    },
  );
  //because we need an array of objects not an array of arrays
  return CsvSheets.flat();
}

function restructureByKey(
  scenarios: ViewMacroScenarioViewModel[],
  value: any[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  const returnArray: { sheet: string; columns: any[]; content: any[] }[] = [];

  value.forEach(({ sheet, path }) => {
    const tableData = getValueAtPath(scenarios[0], path);
    /*to extract the keys list*/
    const firstNestedObj = (tableData as Record<string, any>)[
      Object.keys(tableData)[0]
    ];
    const keys = Object.keys(firstNestedObj);
    /*we'll create a spearte sheet of each key*/
    keys.forEach((key) => {
      let result: {
        sheet: string;
        columns: any[];
        content: any[];
      } = {
        sheet: "",
        columns: [],
        content: [],
      };

      result["sheet"] = sheet + key;

      /*to extract the key names used in the header row*/
      const secondNestedObj = (firstNestedObj as Record<string, any>)[
        Object.keys(firstNestedObj)[0]
      ];

      const headers = Object.keys(secondNestedObj).map((key) => ({
        label: formatText(key),
        value: key,
      }));
      result["columns"] = [{ label: "", value: "metric" }, ...headers];

      const content: any[] = [];

      scenarios.forEach((value) => {
        content.push({}, { metric: formatText(value.name) });
        const scenarioData = getValueAtPath(value, path);
        const tableValues = Object.entries(scenarioData).map(
          ([objKey, obj]) => ({
            ...(Object.fromEntries(
              Object.entries((obj as Record<string, any>)[key]).map(
                ([k, v]) => [k, formatNumber(v as number)],
              ),
            ) as Record<string, any>),
            metric: formatText(objKey),
          }),
        );
        content.push(...tableValues);
      });
      result["content"] = content;

      //add all sheets to the array we will retun for this function
      returnArray.push(result);
    });
  });
  return returnArray;
}

function restructureByObject(
  value: any[],
  keys: string | string[],
  scenarios: ViewMacroScenarioViewModel[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, path }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    const tableData = getValueAtPath(scenarios[0], path);

    const firstNestedObj = (tableData as Record<string, any>)[
      Object.keys(tableData)[0]
    ];

    const arrayOfKeyPairs = Object.entries(firstNestedObj)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => ({
        label: formatText(key),
        value: key,
      }));

    result["columns"] = [{ label: "", value: "metric" }, ...arrayOfKeyPairs];

    const content: any[] = [];

    scenarios.forEach((value) => {
      content.push({}, { metric: formatText(value.name) });
      const scenarioData = getValueAtPath(value, path);

      const tableValues = Object.entries(scenarioData).map(([key, obj]) => ({
        ...(Object.fromEntries(
          Object.entries(obj as Object).map(([k, v]) => [
            k,
            formatNumber(v as number),
          ]),
        ) as Record<string, any>),
        metric: formatText(key),
      }));
      content.push(...tableValues);
    });
    result["content"] = content;

    return result;
  });
}

// function restructureAdjustments(
//   value: any[],
//   keys: string | string[],
//   scenarios: ViewMacroScenarioViewModel[],
// ):
//   | {
//       sheet: string;
//       columns: any[];
//       content: any[];
//     }[]
//   | any[] {
//   const resultsArray: {
//     sheet: string;
//     columns: any[];
//     content: any[];
//   }[] = [];
//   value.forEach(({ sheet, source }) => {
//     const found = scenarios.some((obj: any) =>
//       obj.inputs?.adjustments?.some(
//         (adj: any) => adj.emission_source.toLowerCase() === source,
//       ),
//     );
//     if (found) {
//       let result: {
//         sheet: string;
//         columns: any[];
//         content: any[];
//       } = {
//         sheet: "",
//         columns: [],
//         content: [],
//       };
//
//       result["sheet"] = sheet;
//
//       const arrayOfKeyPairs = scenarios.map((value) => ({
//         label: formatText(value.name),
//         value: value.name,
//       }));
//
//       result["columns"] = [{ label: "", value: "metric" }, ...arrayOfKeyPairs];
//
//       const tableMetric: string[] = [];
//       scenarios.forEach((value) => {
//         value.inputs?.adjustments?.forEach((adjustment: any) => {
//           if (
//             adjustment.emission_source.toLowerCase() === source &&
//             !tableMetric.includes(adjustment.adjustment_name)
//           ) {
//             tableMetric.push(adjustment.adjustment_name);
//           }
//         });
//       });
//       const content: any[] = [];
//
//       tableMetric.forEach((metricName: string) => {
//         const dataObj: any = {
//           metric: formatText(metricName),
//         };
//         scenarios.forEach((scenario: any) => {
//           const foundObject = scenario.inputs?.adjustments?.find(
//             (obj: any) => obj.adjustment_name === metricName,
//           );
//           if (foundObject) {
//             dataObj[scenario.name] =
//               `${foundObject?.base_value} - ${foundObject?.value_change}`;
//           } else {
//             dataObj[scenario.name] = `--`;
//           }
//         });
//         content.push(dataObj);
//       });
//
//       result["content"] = content;
//
//       resultsArray.push(result);
//     }
//   });
//   return resultsArray;
// }

function restructureOverview(
  value: any[],
  scenarios: ViewMacroScenarioViewModel[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, path }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    result["columns"] = getTableColumns(scenarios);

    const content: any[] = [];

    const tableData = getValueAtPath(scenarios[0], path);

    Object.entries(tableData).forEach(([key, obj]) => {
      const dataObj: any = {
        metric: formatText(key),
        [`base_${scenarios[0].id}`]: (obj as any).base,
      };

      scenarios.forEach((scenario) => {
        const scenarioData = getValueAtPath(scenario, path);
        dataObj[`scenario_${scenario.id}`] = formatNumber(
          scenarioData[key].scenario as number,
        );
        dataObj[`glide_${scenario.id}`] = formatNumber(
          scenarioData[key].glide as number,
        );
      });
      content.push(dataObj);
    });

    result["content"] = content;

    return result;
  });
}

function restructureKpis(
  value: any[],
  scenarios: ViewMacroScenarioViewModel[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, path }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    result["columns"] = getTableColumns(scenarios, "kpis");

    const content: any[] = [];

    const tableData = getValueAtPath(scenarios[0], path);

    const firstValue = tableData[Object.keys(tableData)[0]];
    const isNested = typeof firstValue === "object" && firstValue !== null;

    if (isNested) {
      Object.entries(tableData).forEach(([key, obj]) => {
        const dataObj: any = {
          metric: isNested ? formatText(key) : extractName(sheet),
          [`base_${scenarios[0].id}`]: formatNumber(
            (obj as any).base as number,
          ),
          [`unit_${scenarios[0].id}`]: (obj as any).unit,
        };

        scenarios.forEach((scenario) => {
          const scenarioData = getValueAtPath(scenario, path);
          dataObj[`scenario_${scenario.id}`] = formatNumber(
            scenarioData[key].scenario as number,
          );
          dataObj[`glide_${scenario.id}`] = formatNumber(
            scenarioData[key].glide as number,
          );
        });
        content.push(dataObj);
      });
    } else {
      const firstScenarioData = getValueAtPath(scenarios[0], path);

      const dataObj: any = {
        metric: extractName(sheet),
        [`base_${scenarios[0].id}`]: formatNumber(
          firstScenarioData["base"] as number,
        ),
        [`unit_${scenarios[0].id}`]: formatNumber(
          firstScenarioData["unit"] as number,
        ),
      };

      scenarios.forEach((scenario) => {
        const scenarioData = getValueAtPath(scenario, path);
        dataObj[`scenario_${scenario.id}`] = formatNumber(
          scenarioData["scenario"] as number,
        );
        dataObj[`glide_${scenario.id}`] = formatNumber(
          scenarioData["glide"] as number,
        );
      });
      content.push(dataObj);
    }

    result["content"] = content;

    return result;
  });
}

function restructureNested(
  value: any[],
  keys: string | string[],
  scenarios: ViewMacroScenarioViewModel[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, keyName, path }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;
    const tableData = getValueAtPath(scenarios[0], path);

    const firstNested = tableData[Object.keys(tableData)[0]];
    const secondNested = firstNested[Object.keys(firstNested)[0]];
    const thirdNested = secondNested[Object.keys(secondNested)[0]];

    const arrayOfKeyPairs = Object.entries(thirdNested)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => ({
        label: formatText(key),
        value: key,
      }));

    result["columns"] = [{ label: "", value: "metric" }, ...arrayOfKeyPairs];
    const content: any[] = [];

    scenarios.forEach((value) => {
      content.push({}, { metric: formatText(value.name) });
      const scenarioData = getValueAtPath(value, path);
      Object.entries(scenarioData).forEach(([key, parentObj]) => {
        content.push({}, { metric: formatText(key) });
        const typedParentObj = parentObj as { [key: string]: any };

        const tableValues = Object.entries(typedParentObj[keyName]).map(
          ([key, obj]) => ({
            ...(Object.fromEntries(
              Object.entries(obj as Object).map(([k, v]) => [
                k,
                k === "value"
                  ? formatNumber(v as number)
                  : `${formatNumber(v as number)} %`,
              ]),
            ) as Record<string, any>),
            metric: formatText(key),
          }),
        );
        content.push(...tableValues);
      });
    });

    result["content"] = content;
    return result;
  });
}

function getValueAtPath(
  scenario: ViewMacroScenarioViewModel | undefined,
  objectPath: string,
): any {
  if (!scenario) return undefined;

  const keys = objectPath.split(".");
  let result = scenario;

  for (const key of keys) {
    if (result && key in result) {
      result = (result as any)[key];
    } else {
      return undefined;
    }
  }

  return result;
}

export function getTableColumns(
  scenarios: ViewMacroScenarioViewModel[],
  type?: string,
) {
  const columns = [];
  if (type === "kpis") {
    columns.push(
      {
        label: ` `,
        value: `metric`,
      },
      {
        label: `${i18next.t("macro:kpiTable.unit")}`,
        value: `unit_${scenarios[0].id}`,
      },
      {
        label: `${i18next.t("macro:resultsSection.baseYear")}`,
        value: `base_${scenarios[0].id}`,
      },
    );
  } else {
    columns.push(
      {
        label: ` `,
        value: `metric`,
      },
      {
        label: `${i18next.t("macro:resultsSection.baseYear")}`,
        value: `base_${scenarios[0].id}`,
      },
    );
  }
  scenarios.forEach((scenario) => {
    columns.push({
      label: `${i18next.t("macro:resultsSection.glidePath")} (${scenario.name})`,
      value: `glide_${scenario.id}`,
    });

    columns.push({
      label: `${i18next.t("macro:resultsSection.scenario")} (${scenario.name})`,
      value: `scenario_${scenario.id}`,
    });
  });

  return columns;
}

function extractName(name: string) {
  const underscoreIndex = name.indexOf("_");
  const newText =
    underscoreIndex !== -1 ? name.substring(underscoreIndex + 1) : name;
  return formatText(newText);
}

export { getExportData };
