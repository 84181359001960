import { createSlice } from "@reduxjs/toolkit";

type canPack = {
  id: string;
  volume: string;
  category: string;
  size: string;
};
const initialState: canPack[] = [];

export const canPackSlice = createSlice({
  name: "canPack",
  initialState,
  reducers: {
    addCanPack(state, action) {
      return [...state, action.payload];
    },
    removeCanPack(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
