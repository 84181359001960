import { Box, Button, FormGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SKUMinimalViewModel } from "../../../orval/generated/models";
import { FixedSizeList } from "react-window";
import { ProductRow } from "./ProductRow";
import { Dispatch, SetStateAction } from "react";

type SelectProductDropdownProps = {
  filteredSkus: SKUMinimalViewModel[];
  selectedProducts: SKUMinimalViewModel[];
  productLimit: number;
  onChangeSelectProduct: (item: SKUMinimalViewModel, checked: boolean) => void;
  setSelectedProducts: Dispatch<SetStateAction<SKUMinimalViewModel[]>>;
};

export function SelectProductDropdown({
  filteredSkus,
  selectedProducts,
  productLimit,
  onChangeSelectProduct,
  setSelectedProducts,
}: SelectProductDropdownProps) {
  const { spacing } = useTheme();
  const { t } = useTranslation(["common", "micro"]);
  function handleAddAll() {
    setSelectedProducts((prev) => [...prev, ...filteredSkus]);
  }

  function handleClearAll() {
    setSelectedProducts((prev) => {
      return prev.filter((product) => !filteredSkus.includes(product));
    });
  }

  const boxRemHeight = 16;
  const fontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize,
  );
  const verticalSpacing = parseFloat(spacing(7));
  const fixedListPixelHeight = boxRemHeight * fontSize - verticalSpacing;

  return (
    <>
      <Box
        mt={1}
        p={1}
        sx={{
          flexWrap: "nowrap",
          overflowY: "hidden",
          overflowX: "hidden",
          maxHeight: `${boxRemHeight}rem`,
          height: "20em",
        }}
      >
        {filteredSkus.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            my={2}
          >
            <Typography variant="body2">
              {t("common:labels.noResults")}
            </Typography>
          </Box>
        ) : (
          <>
            <FormGroup>
              <FixedSizeList
                height={fixedListPixelHeight}
                itemCount={filteredSkus.length}
                itemSize={50}
                width="100%"
              >
                {({ index, style }) => (
                  <ProductRow
                    index={index}
                    style={style}
                    filteredSkus={filteredSkus}
                    selectedProducts={selectedProducts}
                    productLimit={productLimit}
                    onChangeSelectProduct={onChangeSelectProduct}
                  />
                )}
              </FixedSizeList>
              <Box justifyContent={"space-between"} display={"flex"}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleClearAll}
                  disabled={
                    !filteredSkus.some((product) =>
                      selectedProducts.includes(product),
                    )
                  }
                >
                  {t(
                    "micro:productsSection.selectProductModal.clearSelectedProducts",
                  )}
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleAddAll}
                  disabled={filteredSkus.length > productLimit}
                >
                  {t("micro:productsSection.selectProductModal.addAll")}
                </Button>
              </Box>
            </FormGroup>
          </>
        )}
      </Box>
    </>
  );
}
