import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../ProductsEditableTable";

export const BodyAndLidEmissionFactorsTableMetrics: ProductsEditableColumnConfigItem[] =
  [
    {
      field: "body_virgin_emission_factor",
      label: "micro:bodyAndLidEmissionFactorsSection.table.body_virgin_ef",
      defaultUnit: MicroUnits.PERCENTAGE,
    },
    {
      field: "body_recycled_emission_factor",
      label: "micro:bodyAndLidEmissionFactorsSection.table.body_recycled_ef",
      defaultUnit: MicroUnits.PERCENTAGE,
    },
    {
      field: "lid_virgin_emission_factor",
      label: "micro:bodyAndLidEmissionFactorsSection.table.lid_virgin_ef",
      defaultUnit: MicroUnits.PERCENTAGE,
    },
    {
      field: "lid_recycled_emission_factor",
      label: "micro:bodyAndLidEmissionFactorsSection.table.lid_recycled_ef",
      defaultUnit: MicroUnits.PERCENTAGE,
    },
  ];
