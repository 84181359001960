import { RouteObject } from "react-router-dom";
import { RootPage } from "../../../components/sections/rootpage/RootPage";
import { PolicyPageRoutesConfig } from "./config";
import PolicyPage from "../routes/PolicyPage";
import HomePage from "../../home/routes/HomePage";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";

export const PolicyPageRoutes: RouteObject = {
  path: PolicyPageRoutesConfig.policyHome,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    {
      path: ":policyType",
      element: <PolicyPage />,
    },
    {
      path: "*",
      element: <HomePage />,
    },
  ],
};
