import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import {
  getTableColumns,
  // getTableData
} from "./HelperService";
import { MRT_ColumnDef } from "material-react-table";
import { OverviewTable } from "./OverviewTable";
import { ViewMacroScenarioViewModel } from "../../../../../../orval/generated/models";
import { OptionalMetricsTableResultsType } from "../../../optional-metrics-table/HelperService";

type Props = {
  type: OptionalMetricsTableResultsType;
  scenarios: ViewMacroScenarioViewModel[] | null;
};

export function CarbonEmissionsOverview({ type, scenarios }: Props) {
  const { t } = useTranslation("macro");
  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const [dataAbsolute, setDataAbsolute] = useState<Record<string, string>[]>(
    [],
  );
  const [dataPillar, setDataPillar] = useState<Record<string, string>[]>([]);

  // useEffect(() => {
  //   if (scenarios) {
  //     setColumns(getTableColumns(scenarios));
  //     setDataAbsolute(getTableData(type, scenarios, "absolute"));
  //     setDataPillar(getTableData(type, scenarios, "per_litre"));
  //   }
  // }, [scenarios, type]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="header-H4">
          {t("manageExport.overview")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" gap="32px">
          <OverviewTable
            columns={columns}
            data={dataAbsolute}
            title={t(
              "resultsSection.carbonEmissionOverview.tableHeaders.absoluteEmission",
            )}
          />

          <OverviewTable
            columns={columns}
            data={dataPillar}
            title={t(
              "resultsSection.carbonEmissionOverview.tableHeaders.pillarsEmission",
            )}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
