import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LoaderProps {
  label?: string;
  showLabel?: boolean;
}

export const Loader = ({ label, showLabel }: LoaderProps) => {
  const { t } = useTranslation();
  const loadingMessage = label ? t(label) : t("common:labels.loading");

  return (
    <Box
      sx={{
        height: "171px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CircularProgress />
      {showLabel && <Typography>{loadingMessage}</Typography>}
    </Box>
  );
};
