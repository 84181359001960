import { createSlice } from "@reduxjs/toolkit";

type suppliers = {
  sugar: string;
  shrink: string;
  can: string;
};
const initialState: suppliers = {
  sugar: "",
  shrink: "",
  can: "",
};

export const suppliersSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    addSupplier(state, action) {
      return action.payload;
    },
  },
});
