import { createSlice } from "@reduxjs/toolkit";

type tertiaryPack = {
  id: string;
  emissionFactors: string;
  name: string;
};
const initialState: tertiaryPack[] = [];

export const tertiaryPackSlice = createSlice({
  name: "tertiaryPack",
  initialState,
  reducers: {
    addTertiaryPack(state, action) {
      return [...state, action.payload];
    },
    removeterTiaryPack(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
