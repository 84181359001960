import i18next from "i18next";

import { Typography } from "@mui/material";
import { formatNumber } from "../../../../../utils/formatNumber";
import {
  // MacroResultsKpi,
  ViewMacroScenarioViewModel,
} from "../../../../../orval/generated/models";

// TODO (future): we can rethink how we pass data from backend and improve this function
const otherTypes = [
  "average_sugar_content_per_litre",
  "total_energy_usage_ratio",
  "total_share_dlz",
  "total_weight_sugar",
] as const;

const otherTypesLabels = [
  {
    key: "average_sugar_content_per_litre",
    label: "Average sugar content",
  },
  {
    key: "total_energy_usage_ratio",
    label: "Total EUR",
  },
  {
    key: "total_share_dlz",
    label: "DLZ share",
  },
  {
    key: "total_weight_sugar",
    label: "Total weight sugar",
  },
];

export function getKPITableDataFromScenarios(
  scenarios: ViewMacroScenarioViewModel[],
  kpiKey: string,
) {
  let data: Array<any> = [];
  let columns: Array<any> = [
    {
      id: "pack_type",
      accessorKey: "pack_type",
      header: "",
      size: 140,
    },
    {
      id: "unit",
      accessorKey: "unit",
      header: `${i18next.t("macro:kpiTable.unit")}`,
      size: 150,
    },
  ];
  const checkIfSpecialType = otherTypes.includes(
    kpiKey as (typeof otherTypes)[number],
  );
  // const tObj: MacroResultsKpi = checkIfSpecialType
  //   ? ({ [kpiKey]: scenarios[0]?.results?.kpi[kpiKey] } as MacroResultsKpi)
  //   : (scenarios[0]?.results?.kpi[kpiKey] as MacroResultsKpi);

  // TODO COMMENTED OUT AS KPIS NOT CURRENTLY IN USE OR WORKING
  // Object.keys(tObj).forEach((packType) => {
  //   if (scenarios[0].results) {
  //     const dataObj = {
  //       pack_type: checkIfSpecialType
  //         ? otherTypesLabels.find((type) => type.key === packType)?.label
  //         : packType,
  //       unit: checkIfSpecialType
  //         ? formatNumber(tObj[packType].unit as number)
  //         : formatNumber(
  //             scenarios[0].results.kpi[kpiKey][packType].unit as number,
  //           ),
  //       [`base_${scenarios[0].id}`]: checkIfSpecialType
  //         ? formatNumber(tObj[packType].base as number)
  //         : formatNumber(
  //             scenarios[0].results.kpi[kpiKey][packType].base as number,
  //           ),
  //     };
  //     scenarios.forEach((scenario) => {
  //       if (scenario.results) {
  //         dataObj[`glide_${scenario.id}`] = checkIfSpecialType
  //           ? formatNumber(tObj[packType].glide as number)
  //           : formatNumber(
  //               scenario.results.kpi[kpiKey][packType].glide as number,
  //             );
  //         dataObj[`scenario_${scenario.id}`] = checkIfSpecialType
  //           ? formatNumber(tObj[packType].scenario as number)
  //           : formatNumber(
  //               scenario.results.kpi[kpiKey][packType].scenario as number,
  //             );
  //       }
  //     });
  //     data.push(dataObj);
  //   }
  // });

  Object.keys(data[0]).forEach((key) => {
    const translatedLabel =
      key !== "pack_type" && key !== "unit"
        ? i18next.t(`macro:kpiTable.${key.split("_")[0]}`)
        : key;

    if (!columns.some((column) => column.id === key)) {
      columns = [
        ...columns,

        {
          id: key,
          accessorKey: key,
          header: translatedLabel,
          size: key.includes("base") ? 130 : 200,
          Header: (
            <Typography
              sx={{
                color: key.includes("scenario") ? "#D04A02" : "",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {translatedLabel}
            </Typography>
          ),
          Cell: ({ cell }: any) => (
            <Typography
              sx={{
                color: key.includes("scenario") ? "#D04A02" : "black",
                fontSize: "14px",
              }}
            >
              {cell.getValue()}
            </Typography>
          ),
        },
      ];
    }
  });

  return {
    data,
    columns,
  };
}
