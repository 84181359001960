import { Box, Typography, Link, Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "./Footer.scss";
import "../../sections/rootpage/RootPage.scss";
import { useNavigate } from "react-router-dom";
import { useTermsContext } from "../../../features/terms-and-conditions/components/TermsContext";
import { AppConstant } from "../../../constants";

type FooterProps = {
  applicationVersion: string | undefined;
  masterDatasetVersion: string | undefined;
  content: string;
};

export default function Footer({
  applicationVersion,
  masterDatasetVersion,
  content,
}: FooterProps) {
  const { isTermsAccepted } = useTermsContext();
  const [appVersion, setAppVersion] = useState<string | undefined>(
    () =>
      localStorage.getItem(AppConstant.localStorageKeys.appVersion) ||
      applicationVersion,
  );
  const [masterDataVersion, setMasterDataVersion] = useState<
    string | undefined
  >(() => {
    const storedVersion = localStorage.getItem(
      AppConstant.localStorageKeys.masterDataVersion,
    );
    return storedVersion ? storedVersion : masterDatasetVersion;
  });

  const contentLinks = [
    {
      name: "www.pwc.com/structure",
      href: "https://www.pwc.com/structure",
      target: "_blank",
    },
  ] as const;

  const { t } = useTranslation("common");
  const navigate = useNavigate();

  useEffect((): void => {
    if (isTermsAccepted) {
      if (appVersion !== applicationVersion) {
        localStorage.setItem(
          AppConstant.localStorageKeys.appVersion,
          applicationVersion ?? "",
        );
        setAppVersion(applicationVersion);
      }
      if (masterDataVersion !== (masterDatasetVersion ?? 0)) {
        localStorage.setItem(
          AppConstant.localStorageKeys.masterDataVersion,
          (masterDatasetVersion ?? 0).toString(),
        );
        setMasterDataVersion(masterDatasetVersion);
      }
    }
  }, [
    isTermsAccepted,
    applicationVersion,
    masterDatasetVersion,
    appVersion,
    masterDataVersion,
  ]);

  return (
    <Box className="footer">
      <Box display={"flex"} justifyContent={"left"} gap={2} mb={1}>
        <Link
          href="https://www.pwc.co.uk/who-we-are/legal-disclaimer.html"
          target="_blank"
          underline="none"
          className="footer-text"
        >
          {t("footer.legalDisclaimer")}
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link
          onClick={() => navigate("/legal/terms-of-use")}
          target="_blank"
          underline="none"
          className="footer-text"
        >
          {t("footer.terms")}
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link
          onClick={() => navigate("/legal/privacy-statement")}
          target="_blank"
          underline="none"
          className="footer-text"
        >
          {t("footer.privacy")}
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link
          onClick={() => navigate("/legal/cookie-statement")}
          target="_blank"
          underline="none"
          className="footer-text"
        >
          {t("footer.cookies")}
        </Link>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={9}>
          <Typography className="footer-text">
            {formatContent(content, contentLinks) ||
              "© 2024 PwC. All rights reserved."}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Box display="flex" justifyContent="end">
            {appVersion && masterDataVersion ? (
              <Typography className="footer-text">{`${t("footer.mdVersion")}${masterDataVersion} · ${t("footer.toolVersion")}${appVersion}`}</Typography>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function formatContent(content: any, links: any): string {
  const contentArray = content.split(" ");
  if (contentArray.length > 0) {
    const link = links.find((link: any) => contentArray.includes(link.name));
    if (link) {
      return contentArray.map((word: string, index: number) => {
        if (word === link.name) {
          return (
            <Link href={link.href} target={link.target} key={`footer_${index}`}>
              {link.name}
            </Link>
          );
        }
        return " " + word + " ";
      });
    }
  }
  return content;
}
