import { createSlice } from "@reduxjs/toolkit";
import { Vehicle } from "../../../types";

const initialState: Vehicle[] = [];

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setInitialVehicles(state, action) {
      return action.payload;
    },
    addVehicle(state, action) {
      return [...state, action.payload];
    },
    removeVehicle(state, action) {
      return state.filter((obj) => obj.id !== action.payload);
    },
  },
});
