export const KPISections = [
  "proportion_of_volume",
  "reuse_rate",
  "total_weight_packaging",
  "total_share_dlz",
  "total_weight_sugar",
  "average_sugar_content_per_litre",
  "total_energy_usage_ratio",
  "specific_eur",
  "electricity_source",
  "heat_source",
  "fuel_type_distribution",
];
