import { getCookies, setCookie } from "typescript-cookie";
import { parseJwt } from "../../utils/parseJwt";
import { EnvironmentVariable } from "../../constants";
import { CookieConfig, TokenTypes } from "./enums";
import * as SessionService from "./session-service";

const { localEnvConfig } = EnvironmentVariable;

export const getAccessToken = (): string | undefined => {
  let accessToken =
    SessionService.getSessionTokenForKey(TokenTypes.ACCESS_TOKEN) ||
    process.env[localEnvConfig.accessToken];
  if (!accessToken) {
    try {
      // IMPORTANT - getItem key must be a string value not imported for orval to parse the function correctly
      accessToken = sessionStorage.getItem("accessToken") || "";
    } catch (error) {
      console.log("FAILED to read sessionStorage " + error);
    }
  }
  return accessToken;
};

export const getRefreshToken = (): string | undefined => {
  const accessToken =
    SessionService.getSessionTokenForKey(TokenTypes.REFRESH_TOKEN) ||
    process.env[localEnvConfig.refreshToken];
  return accessToken || undefined;
};

export const getBaseURL = (key: string): string => {
  const baseUrl = process.env[key];
  if (!baseUrl) {
    throw new Error("configured without base URL");
  }
  return baseUrl || "";
};

export const setAccessToken = (accessToken: string) => {
  const cookies = getCookies();
  for (const key in cookies) {
    if (
      key.startsWith(CookieConfig.baseReference) &&
      key.endsWith(TokenTypes.ACCESS_TOKEN)
    ) {
      setCookie(key, accessToken, {
        // We need to replace the cookie set by cognito, which includes a leading `.` in the domain
        domain: `.${window.location.hostname}`,
      });
    }
  }
};

export function extractClientId(): string | undefined {
  const accessToken = getAccessToken();
  const parsedToken = parseJwt(accessToken || "") || {};
  const { client_id } = parsedToken;
  return client_id || undefined;
}
