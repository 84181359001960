import { RouteObject } from "react-router-dom";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { MacroToolRoutesConfig } from "./config";
import MacroToolPage from "../routes/MacroToolPage";
import CreateScenarioPage from "../routes/MacroToolScenarioPage";
import CompareScenarioPage from "../routes/CompareScenarioPage";
import { RootPage } from "../../../components/sections/rootpage/RootPage";

export const MacroToolRoutes: RouteObject = {
  path: MacroToolRoutesConfig.countryLevelAnalytics,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <MacroToolPage /> },
    {
      path: MacroToolRoutesConfig.scenarioPage,
      element: <CreateScenarioPage />,
    },
    {
      path: MacroToolRoutesConfig.compareScenarioPage,
      element: <CompareScenarioPage />,
    },
  ],
};
