import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../custom-select";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface FiltersContainerProps {
  leftFilters: React.ReactNode;
  rightFilters?: React.ReactNode;
}
const FiltersContainer = (props: FiltersContainerProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <Box
      border={"1px #CBCBCB solid"}
      padding={2}
      borderRadius={2}
      display={"flex"}
      flexDirection={"row"}
      gap={2}
      marginLeft={1}
      marginRight={1}
    >
      <Box width={"50%"}>
        <Typography className="header-H4" mb={3}>
          {t("common:filters")}
        </Typography>
        {props.leftFilters}
      </Box>

      {props.rightFilters && (
        <Box borderLeft={"1px solid #CBCBCB"} paddingLeft={2}>
          {props.rightFilters}
        </Box>
      )}
    </Box>
  );
};

export default FiltersContainer;
