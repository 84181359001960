import Box from "@mui/material/Box";
import { AccordionComponent } from "../../common";
import { useTranslation } from "react-i18next";
import { ViewMacroScenarioViewModel } from "../../../../../orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import EmissionTimeSection from "../../emission-time/EmissionTimeSection";
import { TableResultsType } from "../results-tab-section";
import { MekkoSection } from "../mekko-section/MekkoSection";

type DataVisualisationTabSectionProps = {
  disabled: boolean;
  scenario: ViewMacroScenarioViewModel | null;
};

export function DataVisualisationTabSection({
  disabled,
  scenario,
}: DataVisualisationTabSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsTimeEvolution = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <EmissionTimeSection
          scenario={scenario}
          sectionType={TableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <EmissionTimeSection
          scenario={scenario}
          sectionType={TableResultsType.BY_PACK_TYPE}
        />
      ),
    },
  ];

  const subAccordionLabelsWaterfall = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: null,
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: null,
    },
  ];

  return (
    <Box mb={10}>
      <AccordionComponent
        title={t(
          "dataVisualisationTabSection.accordionLabels.emissionsHeatMap"
        )}
        disabled={disabled}
      >
        <MekkoSection disabled={disabled} scenario={scenario} />
      </AccordionComponent>

      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.timeEvolution")}
        disabled={disabled}
      >
        {subAccordionLabelsTimeEvolution.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("dataVisualisationTabSection.accordionLabels.waterfall")}
        disabled={disabled}
      >
        {subAccordionLabelsWaterfall.map((item) => (
          <Box key={item.label} marginLeft={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              <Box display="flex" gap="32px" justifyContent="space-between">
                {item.component}
              </Box>
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
