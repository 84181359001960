import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { SKUMinimalViewModel } from "../../../orval/generated/models";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";

type ProductRowProps = {
  index: number;
  style: React.CSSProperties;
  filteredSkus: SKUMinimalViewModel[];
  selectedProducts: SKUMinimalViewModel[];
  productLimit: number;
  onChangeSelectProduct: (item: SKUMinimalViewModel, checked: boolean) => void;
};

export function ProductRow({
  index,
  style,
  filteredSkus,
  selectedProducts,
  productLimit,
  onChangeSelectProduct,
}: ProductRowProps) {
  const { palette } = useTheme();
  const product = filteredSkus[index];

  const isSelected = selectedProducts.some(
    (selectedItem) => selectedItem.id === product.id,
  );

  return (
    <FormControlLabel
      labelPlacement="start"
      key={`${product.id}-${index}`}
      control={
        <Checkbox
          name={product.product_name}
          checked={isSelected}
          checkedIcon={<DoneIcon />}
          onChange={(_e, checked) => {
            onChangeSelectProduct(product, checked);
          }}
          icon={<AddIcon />}
          disabled={selectedProducts.length >= productLimit && !isSelected}
        />
      }
      label={
        <Box display="flex" flexDirection="column" mb={0.5} mt={0.5}>
          <Typography>{product.product_name}</Typography>
        </Box>
      }
      sx={{
        justifyContent: "space-between",
        marginLeft: 0,
        width: "calc(100% - 4px) !important",
        paddingLeft: 2,
        backgroundColor: isSelected ? palette.activeColor.main : "",
      }}
      style={style}
    />
  );
}
