import React, { forwardRef, HTMLAttributes } from "react";

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, ...props }, ref) => {
    return (
      <div {...props} ref={ref}>
        {props.children}
      </div>
    );
  },
);
