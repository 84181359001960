import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

import { KPISections } from "../../../constants";
import CompareScenariosKPIAccordion from "./CompareScenariosKPIAccordion";
import {ViewMacroScenarioViewModel} from "../../../../../orval/generated/models";

type KPIsProps = {
  disabled: boolean;
  scenarios: ViewMacroScenarioViewModel[] | null;
};
function CompareScenarioKPIsSection({ disabled, scenarios }: KPIsProps) {
  const { t } = useTranslation("macro");
  return (
    <Accordion disabled={disabled}>
      <AccordionSummary className="no-border" expandIcon={<ExpandMoreIcon />}>
        <Typography className="header-H4-bold">
          {t("compareScenarioSection.headersSection.kpis.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {scenarios &&
          KPISections.map((kpi) => (
            <CompareScenariosKPIAccordion
              key={kpi}
              kpiKey={kpi}
              scenarios={scenarios}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default CompareScenarioKPIsSection;
