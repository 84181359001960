import { Box, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  title: string;
  width?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export function SidePanel({ width, title, open, setOpen, children }: Props) {
  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          flexDirection: "row",
        },
      }}
      anchor={"right"}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box
        sx={{
          padding: "16px 16px 16px 32px",
          width: "600px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ borderBottom: "1px solid #E1E1E1" }}
        >
          <Typography className="header-H3">{title}</Typography>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
}
