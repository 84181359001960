import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../ProductsEditableTable/types";

export const ManufacturingTableMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field: "electricity",
    label: "micro:manufacturingSection.table.electricityEur",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "pasteurisation",
    label: "micro:manufacturingSection.table.pasteurisationEur",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "steam",
    label: "micro:manufacturingSection.table.steamEur",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
];
