import { RouteObject } from "react-router-dom";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { MicroToolRoutesConfig } from "./config";
import MicroToolScenarioPage from "../routes/MicroToolScenarioPage/MicroToolScenarioPage";
import MicroViewAllScenariosPage from "../routes/MicroViewAllScenariosPage/MicroViewAllScenariosPage";
import { RootPage } from "../../../components/sections/rootpage/RootPage";

export const MicroToolRoutes: RouteObject = {
  path: MicroToolRoutesConfig.productLevelAnalytics,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <MicroViewAllScenariosPage /> },
    {
      path: MicroToolRoutesConfig.scenarioPage,
      element: <MicroToolScenarioPage />,
    },
  ],
};
