import { Box } from "@mui/material";
import AccountMenu from "../AccountMenu/AccountMenu";
import pwcLogo from "../../../assets/images/PwC_PEF_Logotype.svg";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation("common");
  return (
    <Box className="header">
      <Box>
        <img
          src={pwcLogo as string}
          alt={t("appLogotypeAltText")}
          loading="lazy"
          style={{ minHeight: '26px'}}
        />
      </Box>
      <Box className="settings">
        <AccountMenu></AccountMenu>
      </Box>
    </Box>
  );
}
