import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { generateTableData, TableData } from "./HelperService";
import { MekkoTable } from "./MekkoTable";
import {
  MekkoBaseGlideScenario,
  MekkoResult,
  ViewMacroScenarioViewModel,
} from "../../../../orval/generated/models";

type MekkoUnderlyingDataProps = {
  mekkoData: MekkoResult | undefined;
  scenario: ViewMacroScenarioViewModel | null;
  selectedYear: string;
};

export function MekkoUnderlyingData({
  mekkoData,
  scenario,
  selectedYear,
}: MekkoUnderlyingDataProps) {
  const [tablesData, setTablesData] = useState<TableData | undefined>();
  useEffect(() => {
    if (mekkoData && scenario) {
      const tableData = generateTableData(
        mekkoData,
        selectedYear as keyof MekkoBaseGlideScenario,
      );
      setTablesData(tableData);
    }
  }, [mekkoData, scenario, selectedYear]);
  return (
    <Box>
      {mekkoData && scenario && tablesData && (
        <MekkoTable columns={tablesData.columns} data={tablesData.data} />
      )}
    </Box>
  );
}
