import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useCheckAuthState from "./useCheckAuthState";
import { AuthRoutesConfig } from "../../features";
import { useModal } from "../../components/common/modal";
import TermsOfUse from "../../features/terms-and-conditions/components/TermsOfUse";
import { useTermsContext } from "../../features/terms-and-conditions/components/TermsContext";
import { useMemberInfo } from "../MemberInfoProvider/MemberInfoProvider";
import { Permission, permissionRouteMap } from "./permission";
import { MemberDetails } from "../../orval/generated/models";

interface ProtectedRoutesProps {
  children: React.ReactNode;
}

const checkPermissions = (
  memberInfo: MemberDetails | null,
  routePermission: Permission | undefined,
  hasPermission: (permission: string) => boolean,
): boolean => {
  if (memberInfo && memberInfo?.permissions.functional_access?.length < 1)
    return false;
  if (!routePermission) return true;
  if (!memberInfo) {
    return false;
  }
  return hasPermission(routePermission);
};

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ children }) => {
  const location = useLocation();
  const url = location.pathname + location.search;
  const authFailureRoute = AuthRoutesConfig.idamLogin;
  const [isSignedIn] = useCheckAuthState(url ?? "", authFailureRoute);
  const { openModal, closeModal } = useModal();
  const { isTermsAccepted, setIsTermsAccepted } = useTermsContext();
  const { memberInfo, hasPermission } = useMemberInfo();
  const routePermission = permissionRouteMap[location.pathname];

  if (isSignedIn) {
    if (isTermsAccepted === false) {
      openModal(
        <TermsOfUse
          onClose={closeModal}
          setIsTcsAccepted={setIsTermsAccepted}
        />,
        true,
      );
      return <>{children}</>;
    } else if (isTermsAccepted === true && memberInfo) {
      if (!checkPermissions(memberInfo, routePermission, hasPermission)) {
        return <Navigate to="/access-denied" />;
      }
      return <>{children}</>;
    }
  }
  return <div />;
};

export default ProtectedRoutes;
