import { createSlice } from "@reduxjs/toolkit";

type percentages = {
  state: string;
  newToday: string;
  business: string;
  legacy: string;
};
const initialState: percentages = {
  state: "0",
  newToday: "0",
  business: "0",
  legacy: "0",
};

export const percentChangeSlice = createSlice({
  name: "percentage",
  initialState,
  reducers: {
    addPercentage(state, action) {
      return action.payload;
    },
  },
});
