import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { CustomIcon, Icons } from "../../../../components";
import { TextEdit } from "../../../../components";
import { ProductColumnData } from "../../types";
import ThreeDotMenu from "../../../../components/common/three-dot-menu/ThreeDotMenu";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import { useNavigate } from "react-router-dom";
import { MicroToolRoutesConfig } from "../../navigation";
import "./LandingPageHeroSection.scss";

export type LandingPageHeroSectionProps = {
  title: string;
  onRenameScenario: Function;
  onExportResults: Function;
  onSelectProduct: Function;
  handleRenameDescription: Function;
  isEditScenario?: boolean;
  description: string;
  handleClearProducts: Function;
  handleClearAdjustments: Function;
  products: ProductColumnData[] | undefined;
  skusIsPending: boolean;
};

function LandingPageHeroSection({
  title,
  onRenameScenario,
  onExportResults,
  onSelectProduct,
  isEditScenario,
  description,
  handleRenameDescription,
  handleClearProducts,
  handleClearAdjustments,
  products,
  skusIsPending,
}: LandingPageHeroSectionProps) {
  const { t } = useTranslation(["micro", "common"]);
  const [name, setName] = useState<string>(title);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>(description);
  const descriptionPlaceholder =
    descriptionValue && descriptionValue.length > 0
      ? descriptionValue
      : t("noDescription");
  const [descriptionChanged, setDescriptionChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const redirectURL = MicroToolRoutesConfig.productLevelAnalytics;

  useEffect(() => {
    setName(title);
    setDescriptionValue(description);
  }, [description, title]);

  const handleRenameScenario = () => {
    setEditingName(true);
  };

  const handleInputSave = (value: string) => {
    setName(value);
    onRenameScenario(value);
    setEditingName(false);
  };

  const handleBlurDescription = (value: string) => {
    if (descriptionChanged) {
      handleRenameDescription(value);
      setDescriptionChanged(false);
    }
    setEditDescription(false);
  };

  return (
    <Box maxWidth={`100%`} sx={{ backgroundColor: "white" }}>
      <Button
        variant="text"
        onClick={() => navigate(`${redirectURL}?`)}
        startIcon={<ArrowBackIosSharpIcon />}
      >
        {t("backToMicroHome")}
      </Button>
      <Box display={"flex"} height={"55px"} width="100%">
        <Box display="flex" width={"80%"}>
          {!editingName ? (
            <Box
              onClick={handleRenameScenario}
              maxWidth={"100%"}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleRenameScenario();
                }
              }}
              tabIndex={0}
            >
              <Typography className="header-H2 text-ellipsis micro-header">
                {name}
              </Typography>
            </Box>
          ) : (
            <>
              <TextEdit testId="name" value={name} onSaveInput={handleInputSave}></TextEdit>
            </>
          )}
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          maxWidth={"20%"}
          marginLeft={"auto"}
          minWidth="14em"
        >
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={() => {
              onSelectProduct();
            }}
            startIcon={
              <CustomIcon
                name={Icons.ADD}
                width={"24px"}
                height={"24px"}
                fill="white"
              />
            }
            disabled={skusIsPending}
          >
            {t("micro:heroSectionActions.selectProduct")}
            {skusIsPending && (
              <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
            )}
          </Button>
          <ThreeDotMenu
            menuItems={[
              {
                label: t("heroSectionActions.clearSelection"),
                onClick: () => handleClearProducts(products ?? []),
                disabled: !products?.length,
              },
              {
                label: t("heroSectionActions.clearAdjustments"),
                onClick: () => handleClearAdjustments(),
                disabled: !products?.length,
              },
              // todo add back in once export is fixed
              // {
              //   label: t("heroSectionActions.exportResults"),
              //   onClick: () => onExportResults(),
              //   disabled: !products?.length,
              // },
            ]}
          ></ThreeDotMenu>
        </Box>
      </Box>
      <Box mt={1} width={"95%"} minHeight="45px">
        {editDescription ? (
          <TextField
            sx={{ width: "100%" }}
            placeholder={t("noDescription")}
            variant="outlined"
            data-testid="description"
            value={descriptionValue}
            hiddenLabel
            onChange={(e) => {
              setDescriptionChanged(true);
              setDescriptionValue(e.target.value);
            }}
            onBlur={(e) => handleBlurDescription(e.target.value)}
            autoFocus
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setDescriptionChanged(true);
                handleBlurDescription((e.target as HTMLInputElement).value);
              }
            }}
          />
        ) : (
          <Typography
            sx={
              isEditScenario
                ? { cursor: "pointer", padding: "14px" }
                : { pointerEvents: "none", padding: "14px" }
            }
            className="description-hover"
            onClick={() => setEditDescription(true)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setEditDescription(true);
              }
            }}
            noWrap
            tabIndex={0}
          >
            {descriptionPlaceholder}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default LandingPageHeroSection;
