import { Permission } from "./permission";
import { useMemberInfo } from "../MemberInfoProvider/MemberInfoProvider";

export const usePermissions = () => {
  const { memberInfo, hasPermission } = useMemberInfo();

  const checkPermission = (permission: Permission): boolean => {
    return hasPermission(permission);
  };

  const checkAnyPermission = (permissions: Permission[]): boolean => {
    return permissions.some((permission) => hasPermission(permission));
  };

  const useHasPermission = (permission?: Permission): boolean => {
    if (permission === Permission.ToolConfiguration) {
      return checkAnyPermission([
        Permission.Notifications,
        Permission.UserManagement,
      ]);
    } else if (permission) {
      return checkPermission(permission);
    } else {
      return false; // err on the side of caution
    }
  };

  const hasUserManagementPermission = useHasPermission(
    Permission.UserManagement,
  );

  const hasAlertsPermission = useHasPermission(Permission.Notifications);

  const hasFunctionalAccess =
    memberInfo && memberInfo?.permissions.functional_access.length > 0;

  return {
    useHasPermission,
    hasUserManagementPermission,
    hasAlertsPermission,
    hasFunctionalAccess,
  };
};
