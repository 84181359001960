import { createSlice } from "@reduxjs/toolkit";
import { Fuel } from "../../../types";

const initialState: Fuel[] = [];

export const fuelsSlice = createSlice({
  name: "fuels",
  initialState,
  reducers: {
    setInitialFuels(state, action) {
      return action.payload;
    },
    addFuel(state, action) {
      return [...state, action.payload];
    },
    removeFuel(state, action) {
      return state.filter((obj) => obj.id !== action.payload);
    },
  },
});
