import { IconButton, Typography } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Box from "@mui/material/Box";
import {
  Product,
  SKUMinimalViewModel,
} from "../../../orval/generated/models";

type SelectedProductRowProps = {
  item: SKUMinimalViewModel;
  handleRemoveSelectedProduct: (id: number) => void;
  scenarioProduct?: Product;
};

export function SelectedProductRow({
  item,
  handleRemoveSelectedProduct,
  scenarioProduct,
}: SelectedProductRowProps) {
  return (
    <Box
      key={`${item.id}-${item.id}`}
      width={"90%"}
      display={"flex"}
      flexDirection={"row"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"auto"}
        alignItems={"center"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Typography>
            {scenarioProduct?.custom_display_name ??
              item.product_name}
          </Typography>
        </Box>
      </Box>
      <IconButton
        aria-label="delete"
        sx={{ aspectRatio: 1, marginLeft: "auto" }}
        onClick={() => {
          handleRemoveSelectedProduct(item.id);
        }}
      >
        <CloseSharpIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  );
}
