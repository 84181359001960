// import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

function ProductConfigurationPage() {
  // const { t } = useTranslation("productConfiguration");

  return (
    <Box>
      <Typography variant={"h5"}>Hello this is GenerateTemplatePage</Typography>
    </Box>
  );
}

export default ProductConfigurationPage;
