import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

type DataExtractionFormPartOneProps = {
  handleDataTypeSelect: Function;
  dataTypeSelectValue: string;
};

export default function DataExtractionFormPartOne({
  handleDataTypeSelect,
  dataTypeSelectValue,
}: DataExtractionFormPartOneProps) {
  const { t } = useTranslation("productConfiguration");

  return (
    <FormControl sx={{ minWidth: "300px" }} size={"small"}>
      <InputLabel id="data-type">
        {t("dataManagementPage.dataExtraction.dataType.title")}
      </InputLabel>
      <Select
        labelId="data-extraction-type-label-id"
        id="data-extraction-type-simple-select"
        value={dataTypeSelectValue}
        label={t("dataManagementPage.dataExtraction.dataType.title")}
        onChange={(event) => handleDataTypeSelect(event)}
        size={"small"}
      >
        <MenuItem
          key={t(
            "dataManagementPage.dataExtraction.dataType.configurationData",
          )}
          value={t(
            "dataManagementPage.dataExtraction.dataType.configurationData",
          )}
        >
          {t("dataManagementPage.dataExtraction.dataType.configurationData")}
        </MenuItem>
        <MenuItem
          key={t("dataManagementPage.dataExtraction.dataType.numericData")}
          value={t("dataManagementPage.dataExtraction.dataType.numericData")}
        >
          {t("dataManagementPage.dataExtraction.dataType.numericData")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
