import { Box, Typography } from "@mui/material";
import { ProductsEditableColumnConfigItem } from "../ProductsEditableTable/types";

type props = {
  renderedCellValue: any;
  row: any;
  metrics?: ProductsEditableColumnConfigItem[];
};
export function ColumnWithUnits({ renderedCellValue }: props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography
        sx={{
          textAlign: "left",
          fontWeight: renderedCellValue === "Total" ? "bold" : "normal",
        }}
      >
        {renderedCellValue}
      </Typography>
    </Box>
  );
}
