import { createSlice } from "@reduxjs/toolkit";

export const countrySlice = createSlice({
  name: "country",
  initialState: "",
  reducers: {
    selectedCountry(state, action) {
      return action.payload;
    },
  },
});
