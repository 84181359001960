import { createSlice } from "@reduxjs/toolkit";

type secondaryPack = {
  id: string;
  emissionFactors: string;
  name: string;
};
const initialState: secondaryPack[] = [];

export const secondaryPackSlice = createSlice({
  name: "secondaryPack",
  initialState,
  reducers: {
    addSecondaryPack(state, action) {
      return [...state, action.payload];
    },
    removeSecondaryPack(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
