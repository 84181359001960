import {
  Box,
  Button,
  MenuItem,
  MenuList,
  FormControl,
  Tooltip,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { SearchBar } from "../search-bar";
import "./SelectDropdown.scss";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import "../../../assets/styles/mui-overrides/popover.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type SelectDropdownListItem<T extends string | number> = {
  title?: string;
  value: T | SelectDropdownListItem<T>[];
};

// ? please note the strings in the listItems array should be unique
interface SelectDropdownProps<T extends string | number> {
  listItems: SelectDropdownListItem<T>[] | T[];
  savedSelectedItems: T[];
  onSave: (selectedItems: T[], label?: string) => void;
  onRemove?: (items: T[]) => void;
  title: string;
  selectAll?: boolean;
  requireSave?: boolean;
  showSearchBar?: boolean;
  compact?: boolean;
  formikLabel?: string;
  helperText?: string;
  onOpen?: () => void;
  translate?: boolean;
}

const SelectDropdown = <T extends string | number>(
  props: SelectDropdownProps<T>,
) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selectedItems, setSelectedItems] = useState<Array<T>>([]);
  const [search, setSearch] = useState<string>("");

  const recursiveFilter = (
    items: SelectDropdownListItem<T>[] | Array<T>,
  ): SelectDropdownListItem<T>[] => {
    let result: SelectDropdownListItem<T>[] = [];

    items.forEach((item) => {
      if (typeof item === "string") {
        if (item.toLowerCase().includes(search.toLowerCase())) {
          result.push({ value: item });
        }
      } else if (typeof item === "number") {
        if (item.toString().toLowerCase().includes(search.toLowerCase())) {
          result.push({ value: item });
        }
      } else if (
        typeof item.value === "string" &&
        item.value.toLowerCase().includes(search.toLowerCase())
      ) {
        result.push(item);
      } else if (Array.isArray(item.value)) {
        if (item.title?.toLowerCase().includes(search.toLowerCase())) {
          result.push(item);
        } else {
          const nestedItems = recursiveFilter(item.value);
          if (nestedItems.length > 0) {
            result.push({ ...item, value: nestedItems });
          }
        }
      }
    });
    return result;
  };
  const filteredItems = recursiveFilter(props.listItems);

  const collectValues = (items: SelectDropdownListItem<T>[]): Array<T> => {
    let values: Array<T> = [];
    items.forEach((item) => {
      if (typeof item.value === "string") {
        values.push(item.value);
      } else if (Array.isArray(item.value)) {
        values = values.concat(collectValues(item.value));
      }
    });
    return values;
  };

  const allItemsSelected =
    selectedItems.length === collectValues(filteredItems).length;

  useEffect(() => {
    setSelectedItems(props.savedSelectedItems);
  }, [props.savedSelectedItems]);

  const handleClose = (event?: Event | SyntheticEvent) => {
    if (
      event &&
      anchorRef.current &&
      event.target instanceof Node &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
    setSelectedItems(props.savedSelectedItems);
    setSearch("");
  };

  const addItem = (item: T) => {
    let newSelectedItems: Array<T> = selectedItems.includes(item)
      ? selectedItems.filter((selectedItem: T) => selectedItem !== item)
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);
    if (props.requireSave === false) {
      props.onSave(newSelectedItems, props.formikLabel);
    }
  };

  const addAllItems = () => {
    const newSelectedItems: Array<T> = allItemsSelected
      ? []
      : collectValues(filteredItems);
    setSelectedItems(newSelectedItems);
    if (props.requireSave === false) {
      props.onSave(newSelectedItems, props.formikLabel);
    }
  };

  const handleSave = (selectedItems: Array<T>) => {
    if (props.onRemove) {
      const removedItems: Array<T> = props.savedSelectedItems.filter(
        (item: T) => !selectedItems.includes(item),
      );

      if (removedItems.length > 0) {
        props.onRemove(removedItems);
      }
    }
    props.onSave(selectedItems, props.formikLabel);
    handleClose();
  };

  const generateMenu = (
    items: SelectDropdownListItem<T>[],
    childElement?: boolean,
  ) => {
    return items.map((item: SelectDropdownListItem<T>, index) => {
      if (typeof item.value === "string" || typeof item.value === "number") {
        return (
          <MenuItem
            sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
            onClick={() => {
              addItem(item.value as T);
            }}
            key={item.value}
            data-testid={`${props.title}-select-dropdown-item-${index}`}
          >
            <Tooltip
              key={item.value}
              title={item.value.toString().length > 20 ? item.value : ""}
              placement="right"
            >
              <ListItemText
                primary={
                  !childElement && props.translate
                    ? t(`${item.value}`)
                    : item.value
                }
                secondary={childElement && item.value}
              />
            </Tooltip>
            <Checkbox
              checked={selectedItems.includes(item.value)}
              onClick={() => {
                addItem(item.value as T);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  addItem(item.value as T);
                }
              }}
            />
          </MenuItem>
        );
      } else {
        return (
          <Accordion
            sx={{
              paddingLeft: "1em",
              overflow: "hidden",
            }}
            key={item.title}
            defaultExpanded={props.savedSelectedItems.some((selectedItem) =>
              (item.value as SelectDropdownListItem<T>[]).some(
                (value: SelectDropdownListItem<T>) =>
                  value.value === selectedItem,
              ),
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                border: "none !important",
                margin: "0 !important",
                marginRight: "1em !important",
                minHeight: "0em !important",
                height: "2.5em !important",
              }}
            >
              <Typography className="p">{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {generateMenu(item.value, true)}
            </AccordionDetails>
          </Accordion>
        );
      }
    });
  };

  return (
    <Box sx={{ zIndex: 20 }}>
      {open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 29,
          }}
          onClick={handleClose}
        />
      )}
      <FormControl
        variant="outlined"
        fullWidth
        size={props.compact ? "small" : undefined}
      >
        <InputLabel htmlFor={props.title}>{props.title}</InputLabel>
        <Select
          id={props.title}
          multiple
          label={props.title}
          open={open}
          onClose={() => {
            handleClose();
          }}
          onOpen={() => {
            setOpen(true);
            props.onOpen && props.onOpen();
          }}
          labelId="add-product-brand"
          value={props.savedSelectedItems}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return null;
            }
            const translatedSelected = props.translate
              ? selected.map((item) => t(`${item}`))
              : selected;
            return translatedSelected.join(", ");
          }}
          data-testid={`${props.title}-select-dropdown`}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {(props.listItems.length > 9 || props.showSearchBar) && (
                <Box sx={{ padding: 1 }}>
                  <SearchBar handleSearch={setSearch}></SearchBar>
                </Box>
              )}
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedItems([]);
                  if (!props.requireSave) {
                    props.onSave([], props.formikLabel);
                  }
                }}
                sx={{ marginTop: 1 }}
                className="button clear"
                disabled={selectedItems.length === 0}
                data-testid={`${props.title}-select-dropdown-clear`}
              >
                {t("common:actions.clear")}{" "}
                {selectedItems.length !== 0 ? `(${selectedItems.length})` : ""}
              </Button>
            </Box>
            <MenuList
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={{
                height: "14em",
                overflow: "auto",
              }}
            >
              {props.selectAll && filteredItems.length !== 0 && (
                <MenuItem
                  key={t("actions.selectAll")}
                  value={t("actions.selectAll")}
                  sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
                  onClick={() => {
                    addAllItems();
                  }}
                >
                  <ListItemText primary={t("actions.selectAll")} />
                  <Checkbox
                    checked={allItemsSelected}
                    onClick={() => {
                      addAllItems();
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        addAllItems();
                      }
                    }}
                  />
                </MenuItem>
              )}
              {filteredItems.length === 0 ? (
                <MenuItem
                  sx={{ height: "2.5em", maxWidth: "100%", overflow: "hidden" }}
                >
                  <Tooltip title={"No items found"} placement="right">
                    <ListItemText primary={"No items found"} />
                  </Tooltip>
                </MenuItem>
              ) : (
                <Box>{generateMenu(filteredItems)}</Box>
              )}
            </MenuList>
            {props.requireSave !== false && (
              <Box>
                <Button
                  variant="outlined"
                  className="button footer"
                  onClick={handleClose}
                  data-testid={`${props.title}-select-dropdown-cancel`}
                >
                  {t("common:actions.cancel")}
                </Button>
                <Button
                  variant="outlined"
                  className="button footer"
                  onClick={() => {
                    handleSave(selectedItems);
                  }}
                  disabled={filteredItems.length === 0}
                  data-testid={`${props.title}-select-dropdown-apply`}
                >
                  {t("common:actions.apply")}
                </Button>
              </Box>
            )}
          </Box>
        </Select>
        <FormHelperText error>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default SelectDropdown;
