import {
  MicroUnits,
  ProductsEditableColumnConfigItem,
} from "../ProductsEditableTable";

export const LogisticsTableMetrics: ProductsEditableColumnConfigItem[] = [
  {
    field: "primary_distribution_distance",
    label: "micro:logisticsSection.table.primary_road",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "secondary_distribution_distance",
    label: "micro:logisticsSection.table.secondary_road",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "ship_distance_travelled",
    label: "micro:logisticsSection.table.ship",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "train_distance_travelled",
    label: "micro:logisticsSection.table.train",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
  {
    field: "plane_distance_travelled",
    label: "micro:logisticsSection.table.plane",
    defaultUnit: MicroUnits.PERCENTAGE,
  },
];
