import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { CustomTabPanel, CustomTabs } from "../../../components";
import { useState } from "react";
import { dataManagementTabs } from "./DataManagementTabs";
import VersionHistory from "../../data-management/components/VersionHistory/VersionHistory";
import DataExtraction from "../../data-management/components/DataExtraction/DataExtraction";

function DataManagementPage() {
  const { t } = useTranslation("productConfiguration");
  const [value, setValue] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "27px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box>
        <Typography className={"header-H2"} fontWeight="500">
          {t("dataManagementPage.title")}
        </Typography>
        <Typography mt={2} variant="body1">
          {t("dataManagementPage.description")}
        </Typography>
      </Box>
      <Box>
        <Box borderBottom={1} borderColor="divider">
          <CustomTabs
            tabList={dataManagementTabs.tabs}
            value={value}
            setValue={setValue}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          <VersionHistory />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DataExtraction />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}

export default DataManagementPage;
