import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./GlobalLoader.scss";

type GlobalLoaderContextProps = {
  showGlobalLoader: Dispatch<SetStateAction<boolean>>;
};

const GlobalLoaderContext = createContext<GlobalLoaderContextProps | undefined>(
  undefined,
);

export function GlobalLoaderProvider(props: { children: React.ReactNode }) {
  const [open, showGlobalLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 5000); // 5 seconds
    } else {
      setShowMessage(false);
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <GlobalLoaderContext.Provider value={{ showGlobalLoader }}>
      <Backdrop
        sx={(theme) => ({ color: theme.palette.primary.main, zIndex: 1301 })}
        open={open}
      >
        <Box className={`globalLoaderClass ${showMessage && "show"}`}>
          <Box>
            <CircularProgress color="inherit" />
          </Box>
          <Box className={`message ${showMessage && "show"}`}>
            {showMessage && (
              <>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography className={"header-H5"} sx={{ mt: 2 }}>
                    Just a moment,
                  </Typography>
                </Box>
                <Box>
                  <Typography className={"header-H5"}>
                    we'll take you there soon.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Backdrop>
      {props.children}
    </GlobalLoaderContext.Provider>
  );
}

export function useGlobalLoader() {
  const context = useContext(GlobalLoaderContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalLoader must be used within a GlobalLoaderProvider",
    );
  }
  return context;
}
