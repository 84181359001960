import { createSlice } from "@reduxjs/toolkit";
import { Category } from "../../../types";

const initialState: Category[] = [];

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,
  reducers: {
    setInitialProductCategories(state, action) {
      return action.payload;
    },
    addProductCategory(state, action) {
      return [...state, action.payload];
    },
    removeProductCategory(state, action) {
      return state.filter((obj) => obj.id !== action.payload);
    },
  },
});
