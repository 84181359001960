import CustomAccordion from "./CustomAccordion";
import {
  Product,
  SKUAdjustmentAccordionDetails,
  ViewMicroScenario,
} from "../../../../orval/generated/models";
import { ProductColumnData } from "../../types";
import RenderAccordions from "./RenderAccordions";
import { ProductConfigurationsMicro } from "../ProductsEditableTable";

type AdjustmentAccordionProps = {
  productColumnData: ProductColumnData[] | undefined;
  products: Product[] | undefined;
  scenario: ViewMicroScenario | undefined;
  updateMicroScenario: Function;
  accordion: SKUAdjustmentAccordionDetails;
};

function AdjustmentAccordion({
  products,
  productColumnData,
  scenario,
  updateMicroScenario,
  accordion,
}: AdjustmentAccordionProps) {
  const accordionDetails =
    products && RenderAccordions(accordion, productColumnData, products, accordion.accordion === ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS);

  return <CustomAccordion
    title={accordion.accordion}
    hasSubAccordions={Boolean(accordion.sub_accordions && accordion.sub_accordions.length)}
    details={accordionDetails}
  />

}

export default AdjustmentAccordion;
