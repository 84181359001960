import { Navigate, RouteObject } from "react-router-dom";
import { HomeRoutes, MicroToolRoutes } from "../../features";
import { MacroToolRoutes } from "../../features/macro-tool";
import { ToolConfigurationRoutes } from "../../features/tool-configuration";
import { PolicyPageRoutes } from "../../features/policy-pages";
import { SupportingPagesRoutes } from "../../features/supporting-pages";
import { DataManagementRoutes } from "../../features/data-management/navigation";
import { AuthNavigationRoutes } from "../../features/auth/navigation/routes";

const AppRoutes: RouteObject[] = [
  {
    path: "/",
    children: [
      { index: true, element: <Navigate to={"/auth"} /> },
      { path: "*", element: <Navigate to={"/auth"} /> },
    ],
  },
  AuthNavigationRoutes,
  HomeRoutes,
  SupportingPagesRoutes,
  PolicyPageRoutes,
  DataManagementRoutes,
  MicroToolRoutes,
  MacroToolRoutes,
  ToolConfigurationRoutes,
];

export default AppRoutes;
