/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Product Emissions Forecaster
 * OpenAPI spec version: 3.1
 */

export type MemberSortOrder =
  (typeof MemberSortOrder)[keyof typeof MemberSortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MemberSortOrder = {
  updated_on_asc: "updated_on_asc",
  updated_on_desc: "updated_on_desc",
  name_asc: "name_asc",
  name_desc: "name_desc",
} as const;
