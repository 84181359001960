import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CustomIcon, Icons } from "../../../../components/common/custom-icon";
import { Dispatch, SetStateAction } from "react";

type CompareScenarioHeroSectionProps = {
  onExportScenario: () => void;
  setEdit: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
};

export function CompareScenarioHeroSection({
  onExportScenario,
  setEdit,
  disabled,
}: CompareScenarioHeroSectionProps) {
  const { t } = useTranslation("macro");
  return (
    <Box pr={2} pt={1}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography fontWeight={500} variant="h1">
            {t("compareScenarioSection.title")}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            disabled={disabled}
            onClick={onExportScenario}
            startIcon={
              <CustomIcon
                name={Icons.DOWNLOAD_SIMPLE}
                width={"24px"}
                height={"24px"}
                fill="white"
              />
            }
          >
            {t("buttonLabel.exportResults")}
          </Button>
          <Button
            onClick={() => setEdit(true)}
            variant="contained"
            color="primary"
          >
            {t("compareScenarioSection.buttonLabel.editScenarios")}
          </Button>
        </Stack>
      </Box>
      <Box py={2}>
        <Typography>{t("compareScenarioSection.description")}</Typography>
      </Box>
    </Box>
  );
}
