import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CategoryViewModel,
  CountryViewModel,
  DataExtractRequest,
  PackTypeViewModel,
  YearViewModel,
} from "../../../../../orval/generated/models";
import {
  useGetCategoriesCategoriesGet,
  useGetMemberCountriesMembersCountriesGet,
  useGetPackTypesPackTypesGet,
  useGetYearsYearsGet,
} from "../../../../../orval/generated/endpoint";
import { useEffect } from "react";
import { useGlobalLoader } from "../../../../../components";
import { useSnackbar } from "../../../../../components/common/notification/showSnackbar";
import { AxiosError } from "axios";
import { FormikProps } from "formik";
import SelectDropdown from "../../../../../components/common/select-dropdown/SelectDropdown";

type DataExtractionFormPartThreeProps = {
  formik: FormikProps<DataExtractRequest>;
};

type DataExtractionFilterDropDown = {
  data:
    | CountryViewModel[]
    | CategoryViewModel[]
    | PackTypeViewModel[]
    | YearViewModel[]
    | undefined;
  labelKey: string;
  formikKey: string;
  savedItems: string[] | undefined;
  onSave: (selectedItems: number[] | string[]) => number[] | string[];
};

export default function DataExtractionFormPartThree({
  formik,
}: DataExtractionFormPartThreeProps) {
  const { t } = useTranslation("productConfiguration");
  const { showGlobalLoader } = useGlobalLoader();
  const showSnackbar = useSnackbar();

  const {
    data: countryDropdownData,
    isPending: countryDropdownDataIsPending,
    error: countryDropdownDataError,
  } = useGetMemberCountriesMembersCountriesGet();

  const {
    data: yearsDropdownData,
    isPending: yearsDropdownDataIsPending,
    error: yearsDropdownDataError,
  } = useGetYearsYearsGet();

  const {
    data: categoriesDropdownData,
    isPending: categoriesDropdownDataIsPending,
    error: categoriesDropdownDataError,
  } = useGetCategoriesCategoriesGet();

  const {
    data: packTypesDropdownData,
    isPending: packTypesDropdownDataIsPending,
    error: packTypesDropdownDataError,
  } = useGetPackTypesPackTypesGet();

  const dropdowns: DataExtractionFilterDropDown[] = [
    {
      data: countryDropdownData,
      labelKey: "dataManagementPage.dataExtraction.optionalDropdowns.countries",
      formikKey: "country",
      savedItems:
        formik.values.filters?.country?.map(
          (countryCode: string) =>
            countryDropdownData!.find(
              (country: CountryViewModel) => country.country === countryCode,
            )!.display_name,
        ) ?? [],
      onSave: (selectedItems: number[] | string[]) => {
        return (selectedItems as string[]).map(
          (item: string) =>
            (countryDropdownData as CountryViewModel[])!.find(
              (d: CountryViewModel) => d.display_name === item,
            )!.country,
        );
      },
    },
    {
      data: categoriesDropdownData,
      labelKey: "dataManagementPage.dataExtraction.optionalDropdowns.category",
      formikKey: "category",
      savedItems: formik.values.filters?.category?.map(
        (selectedCategory: number) =>
          categoriesDropdownData!.find(
            (category: CategoryViewModel) => category.id === selectedCategory,
          )!.category,
      ),
      onSave: (selectedItems: number[] | string[]) => {
        return (selectedItems as string[]).map(
          (item: string) =>
            (categoriesDropdownData as CategoryViewModel[])!.find(
              (d: CategoryViewModel) => d.category === item,
            )!.id,
        );
      },
    },
    {
      data: packTypesDropdownData,
      labelKey: "dataManagementPage.dataExtraction.optionalDropdowns.packType",
      formikKey: "pack_type",
      savedItems: formik.values.filters?.pack_type?.map(
        (selectedPack: number) =>
          packTypesDropdownData!.find(
            (pack: PackTypeViewModel) => pack.id === selectedPack,
          )!.pack_type,
      ),
      onSave: (selectedItems: number[] | string[]) => {
        return (selectedItems as string[]).map(
          (selectedPack: string) =>
            (packTypesDropdownData as PackTypeViewModel[])!.find(
              (d: PackTypeViewModel) => d.pack_type === selectedPack,
            )!.id,
        );
      },
    },
    {
      data: yearsDropdownData,
      labelKey: "dataManagementPage.dataExtraction.optionalDropdowns.years",
      formikKey: "year",
      savedItems:
        formik.values.filters?.year?.map((year) => year.toString()) ?? [],
      onSave: (selectedItems: number[] | string[]) => {
        return selectedItems;
      },
    },
  ];

  function isCountryViewModel(item: any): item is CountryViewModel {
    return "country" in item;
  }

  function isCategoryViewModel(item: any): item is CategoryViewModel {
    return "category" in item;
  }

  function isPackTypeViewModel(item: any): item is PackTypeViewModel {
    return "pack_type" in item;
  }

  function isYearViewModel(item: any): item is YearViewModel {
    return "year" in item;
  }

  useEffect(() => {
    countryDropdownDataIsPending ||
    yearsDropdownDataIsPending ||
    categoriesDropdownDataIsPending ||
    packTypesDropdownDataIsPending
      ? showGlobalLoader(true)
      : showGlobalLoader(false);
  }, [
    countryDropdownDataIsPending,
    yearsDropdownDataIsPending,
    categoriesDropdownDataIsPending,
    packTypesDropdownDataIsPending,
  ]);

  useEffect(() => {
    if (
      countryDropdownDataError ||
      yearsDropdownDataError ||
      categoriesDropdownDataError ||
      packTypesDropdownDataError
    ) {
      const error =
        countryDropdownDataError ||
        yearsDropdownDataError ||
        categoriesDropdownDataError ||
        packTypesDropdownDataError;
      showSnackbar(error as AxiosError<unknown, any>, "error");
    }
  }, [
    countryDropdownDataError,
    yearsDropdownDataError,
    categoriesDropdownDataError,
    packTypesDropdownDataError,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        {dropdowns.map(
          ({ data, labelKey, formikKey, onSave, savedItems }, index) => (
            <Grid item sm={3} key={index}>
              <SelectDropdown<string>
                title={t(labelKey)}
                listItems={
                  data?.map(
                    (
                      item:
                        | CountryViewModel
                        | CategoryViewModel
                        | PackTypeViewModel
                        | YearViewModel,
                    ) => {
                      let display;
                      if (isCountryViewModel(item)) {
                        display = item.display_name;
                      } else if (isCategoryViewModel(item)) {
                        display = item.category;
                      } else if (isPackTypeViewModel(item)) {
                        display = item.pack_type;
                      } else if (isYearViewModel(item)) {
                        display = item.year.toString();
                      } else {
                        return "";
                      }
                      return display;
                    },
                  ) ?? []
                }
                savedSelectedItems={savedItems ?? []}
                onSave={(selectedItems: number[] | string[]) => {
                  const dataToSave = onSave && onSave(selectedItems);
                  formik.setFieldValue(`filters.${formikKey}`, dataToSave);
                }}
                selectAll
              />
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
}
