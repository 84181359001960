import { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { AppConstant } from "../../../constants";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import Box from "@mui/material/Box";
import "./RootPage.scss";
import BannerAlert from "../../common/BannerAlert/BannerAlert";
import { useMetadata } from "../../../features/tool-configuration/components/BannerAlertConfigurationPage/MetadataContext";

type Context = { setHeaderText: (_: string) => void };

export function RootPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeAlert, applicationVersion, masterDatasetVersion } =
    useMetadata();
  const [headerText, setHeaderText] = useState<string | null>(
    AppConstant.appName,
  );

  return (
    <Box className="root-page">
      <Header />
      <Box className="content">
        <Sidebar />
        {activeAlert ? <BannerAlert activeAlert={activeAlert} /> : null}
        <Box className="outlet">
          <Outlet context={{ setHeaderText }} />
        </Box>
        <Box>
          <Footer
            content={AppConstant.footerContent.content}
            applicationVersion={applicationVersion?.toString()}
            masterDatasetVersion={masterDatasetVersion}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function useContext() {
  return useOutletContext<Context>();
}
