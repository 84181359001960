import { formatNumber } from "../../../../utils/formatNumber";
import { formatText } from "../../../../utils/formatText";

function getExportData(
  selectedOptions: string[],
  optionValues: [] | { label: string; keys: string[]; value: object[] }[],
) {
  //Extract an array of selected option objects
  const exportObjects = optionValues.filter((obj) =>
    selectedOptions.includes(obj.label),
  );
  //Map on each option object that needs to be exported
  const CsvSheets = exportObjects.map(
    ({
      label,
      keys,
      value,
    }: {
      label: String;
      keys: string[];
      value: any[];
    }) => {
      //restructure object dependnig on its table structure
      if (label === "absoulteEmission" || label === "perLitreEmission") {
        return restructureByKey(value);
      } else if (label === "movementEmission") {
        return restructureNested(value, keys);
      } else if (label === "mekkoUnderlying") {
        return restructureMekko(value, keys);
      } else {
        //keys in this parameter refer to the keys we want to include in the exported table
        return restructureByObject(value, keys);
      }
    },
  );
  //because we need an array of objects not an array of arrays
  return CsvSheets.flat();
}

function restructureByKey(value: any[]): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  const returnArray: { sheet: string; columns: any[]; content: any[] }[] = [];

  value.forEach(({ sheet, tableData }) => {
    /*to extract the keys list*/
    const firstNestedObj = (tableData as Record<string, any>)[
      Object.keys(tableData)[0]
    ];
    const keys = Object.keys(firstNestedObj);
    /*we'll create a spearte sheet of each key*/
    keys.forEach((key) => {
      let result: {
        sheet: string;
        columns: any[];
        content: any[];
      } = {
        sheet: "",
        columns: [],
        content: [],
      };

      result["sheet"] = sheet + key;

      /*to extract the key names used in the header row*/
      const secondNestedObj = (firstNestedObj as Record<string, any>)[
        Object.keys(firstNestedObj)[0]
      ];

      const headers = Object.keys(secondNestedObj).map((key) => ({
        label: formatText(key),
        value: key,
      }));
      result["columns"] = [{ label: "", value: "metric" }, ...headers];

      result["content"] = Object.entries(tableData).map(([objKey, obj]) => ({
        ...(Object.fromEntries(
          Object.entries((obj as Record<string, any>)[key]).map(([k, v]) => [
            k,
            formatNumber(v as number),
          ]),
        ) as Record<string, any>),
        metric: formatText(objKey),
      }));
      //add all sheets to the array we will retun for this function
      returnArray.push(result);
    });
  });
  return returnArray;
}

function restructureByObject(
  value: any[],
  keys: string | string[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, tableData }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    const firstNestedObj = (tableData as Record<string, any>)[
      Object.keys(tableData)[0]
    ];

    const arrayOfNestedKeyPairs = Object.entries(firstNestedObj)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => {
        return {
          label: formatText(key),
          value: key,
        };
      });

    const arrayOfKeyPairs = Object.entries(tableData)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => {
        return {
          label: formatText(key),
          value: key,
        };
      });

    result["columns"] = arrayOfNestedKeyPairs.length
      ? [{ label: "", value: "metric" }, ...arrayOfNestedKeyPairs]
      : [{ label: "", value: "metric" }, ...arrayOfKeyPairs];

    result["content"] = arrayOfNestedKeyPairs.length
      ? Object.entries(tableData).map(([key, obj]) => {
          return {
            ...(Object.fromEntries(
              Object.entries(obj as Record<string, any>).map(([k, v]) => [
                k,
                formatNumber(v as number),
              ]),
            ) as Record<string, any>),
            metric: formatText(key),
          };
        })
      : [
          {
            metric: extractName(sheet),
            ...Object.fromEntries(
              Object.entries(tableData).map(([key, value]) => [
                key,
                formatNumber(value as number),
              ]),
            ),
          },
        ];

    return result;
  });
}

function restructureNested(
  value: any[],
  keys: string | string[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, tableData, keyName }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    const firstNested = tableData[Object.keys(tableData)[0]];
    const secondNested = firstNested[Object.keys(firstNested)[0]];
    const thirdNested = secondNested[Object.keys(secondNested)[0]];

    const arrayOfKeyPairs = Object.entries(thirdNested)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => ({
        label: formatText(key),
        value: key,
      }));

    result["columns"] = [{ label: "", value: "metric" }, ...arrayOfKeyPairs];
    const content: any[] = [];

    Object.entries(tableData).forEach(([key, parentObj]) => {
      content.push({}, { metric: formatText(key) });
      const typedParentObj = parentObj as { [key: string]: any };

      const tableValues = Object.entries(typedParentObj[keyName]).map(
        ([key, obj]) => ({
          ...(Object.fromEntries(
            Object.entries(obj as Object).map(([k, v]) => [
              k,
              k === "value"
                ? formatNumber(v as number)
                : `${formatNumber(v as number)} %`,
            ]),
          ) as Record<string, any>),
          metric: formatText(key),
        }),
      );
      content.push(...tableValues);
    });
    result["content"] = content;
    return result;
  });
}

function restructureMekko(
  value: any[],
  keys: string | string[],
): {
  sheet: string;
  columns: any[];
  content: any[];
}[] {
  return value.map(({ sheet, tableData, keyName }) => {
    let result: {
      sheet: string;
      columns: any[];
      content: any[];
    } = {
      sheet: "",
      columns: [],
      content: [],
    };

    result["sheet"] = sheet;

    const firstNested = tableData[Object.keys(tableData)[0]];
    const secondNested = firstNested[keyName];
    const thirdNested = secondNested[Object.keys(secondNested)[0]];
    const fourthNested = thirdNested[Object.keys(thirdNested)[0]];

    const arrayOfKeyPairs = Object.entries(fourthNested)
      .filter(([key]) => keys.length === 0 || keys.includes(key)) // Depends if we want to print all keys in the table or some keys only
      .map(([key]) => ({
        label: formatText(key),
        value: key,
      }));
    result["columns"] =
      keyName === "absolute"
        ? [
            { label: "", value: "metric" },
            { label: "Metric Type", value: "subMetric" },
            ...arrayOfKeyPairs,
          ]
        : [{ label: "", value: "metric" }, ...arrayOfKeyPairs];

    const content: any[] = [];

    Object.entries(tableData).forEach(([key, parentObj]) => {
      content.push({}, { metric: formatText(key) });
      const typedParentObj = parentObj as { [key: string]: any };
      Object.entries(typedParentObj[keyName]).forEach(
        ([childKey, childObj]) => {
          const tableValues = Object.entries(childObj as object).map(
            ([key, obj]) => ({
              ...(Object.fromEntries(
                Object.entries(obj as Record<string, any>).map(([k, v]) => [
                  k,
                  formatNumber(v as number),
                ]),
              ) as Record<string, any>),

              metric: formatText(key),
              subMetric: formatText(childKey),
            }),
          );
          content.push(...tableValues);
        },
      );
    });

    result["content"] = content;
    return result;
  });
}
function extractName(name: string) {
  const underscoreIndex = name.indexOf("_");
  const newText =
    underscoreIndex !== -1 ? name.substring(underscoreIndex + 1) : name;
  return formatText(newText);
}
export { getExportData };
