import * as React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Email, Person } from "@mui/icons-material";
import { getDecodedSessionInfo } from "../../../core/http/session-service";
import { TokenTypes } from "../../../core/http/enums";
import { NamespaceMappings } from "../../../core/localisation/config";
import { IdamUser, useAuthUser } from "../../../core";
import { revokeTokenAndEndSession } from "../../../features/auth/utils/verifyPwCAccess";
import { AppConstant } from "../../../constants";

export default function AccountMenu() {
  const { t } = useTranslation(NamespaceMappings.common);
  const sessionIDInfo: any = getDecodedSessionInfo(TokenTypes.ID_TOKEN);
  const userInfo: IdamUser | undefined = useAuthUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      await revokeTokenAndEndSession();
      localStorage.removeItem(
        AppConstant.localStorageKeys.dismissedBannerMessage,
      );
    } catch (err) {
      throw err;
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("accountMenu.accountSettings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              alt={sessionIDInfo?.name || userInfo?.name}
              src={sessionIDInfo?.picture}
              sx={{ bgcolor: "#d04a02", fontSize: 16 }}
            >
              {`${userInfo?.given_name?.charAt(0)}${userInfo?.family_name?.charAt(0)}`}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              mr: 0.8,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ cursor: "default" }} onClick={handleClose}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          {sessionIDInfo?.name || userInfo?.name}
        </MenuItem>
        <MenuItem sx={{ cursor: "default" }} onClick={handleClose}>
          <ListItemIcon>
            <Email fontSize="small" />
          </ListItemIcon>
          {sessionIDInfo?.preferredMail || userInfo?.preferredMail}
        </MenuItem>
        <Divider />
        <MenuItem sx={{ cursor: "pointer" }} onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
