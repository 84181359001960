import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import SingleScenario from "../scenario-list/SingleScenario";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MacroToolRoutesConfig } from "../../navigation";
import { NotificationsContext } from "../../../../components";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";

type Props = {
  scenarios: ViewMacroScenarioViewModel[] | null;
  scenarioIds: string[];
  setEdit: Dispatch<SetStateAction<boolean>>;
};

export function CompareScenarioModal({
  setEdit,
  scenarios,
  scenarioIds,
}: Props) {
  const { t } = useTranslation("macro");
  const navigate = useNavigate();
  const { setNotificationState } = useContext(NotificationsContext);

  const redirectURL = MacroToolRoutesConfig.compareScenarioPage;
  const [selectedOptions, setSelectedOptions] = useState<string[]>(scenarioIds);
  const [chosenBaseYears, setChosenBaseYears] = useState<number[]>([]);

  function redirect() {
    const valid = allEqual(chosenBaseYears);

    if (valid) {
      const params = createSearchParams({
        scenarioId: selectedOptions,
      });

      navigate(`${redirectURL}?${params}`);
    } else {
      setNotificationState({
        open: true,
        message: t("invalidBaseYear"),
        state: "error",
      });
    }
  }

  function allEqual(arr: number[]): boolean {
    return new Set(arr).size === 1;
  }

  function onChangeSelectOption(
    item: number,
    checked: boolean,
    base_year: number,
  ): void {
    if (checked) {
      setSelectedOptions([...selectedOptions, item.toString()]);
      setChosenBaseYears([...chosenBaseYears, base_year]);
    } else {
      setSelectedOptions(
        selectedOptions.filter(
          (selectedItem) => selectedItem !== item.toString(),
        ),
      );
      setChosenBaseYears(
        chosenBaseYears.filter((selectedItem) => selectedItem !== base_year),
      );
    }
  }

  return (
    <Box p={1}>
      <Box display="flex" justifyContent="end">
        <IconButton
          aria-label="delete"
          sx={{ aspectRatio: 1 }}
          onClick={() => setEdit(false)}
        >
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box mx={2}>
        <Box mb={3}>
          <Typography textAlign="center" variant="h3" fontWeight={500}>
            {t("buttonLabel.editScenarios")}
          </Typography>
          <Typography textAlign="left" p={2} variant="body2">
            {t("micro:loadScenarioSection.description")}
          </Typography>
        </Box>
        <Box mb={3}>
          <Box sx={{ overflow: "auto", maxHeight: "50vh" }}>
            {scenarios
              ? scenarios.map((data) => (
                  <Box key={data.id}>
                    {/*<SingleScenario*/}
                    {/*  selectedOptions={selectedOptions}*/}
                    {/*  onChangeSelectOption={(item, checked, base_year) =>*/}
                    {/*    onChangeSelectOption(item, checked, base_year || 0)*/}
                    {/*  }*/}
                    {/*  data={data}*/}
                    {/*  key={data.id}*/}
                    {/*/>*/}
                  </Box>
                ))
              : null}
          </Box>

          <Box display="flex" justifyContent="center" p={4}>
            <Stack direction="row" spacing={2} margin="auto">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setEdit(false)}
              >
                {t("common:actions.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={redirect}
                disabled={selectedOptions.length < 2}
              >
                {t("common:actions.add")}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
