export function getDateFormat(date: string): string {
  const dateObj = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = dateObj.toLocaleDateString("en-GB", options);
  return formattedDate;
}
