import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { CustomTabPanel, CustomTabs } from "../../../../components";
import { scenarioResultsTabs } from "../../constants";
import AdjustmentsList from "../AdjustmentsList";
import { Adjustment } from "../../types";
import { SidePanel } from "../../../../components/common/side-panel/SidePanel";
import { MekkoSection } from "./mekko-section";
import {
  UpdateMacroScenarioViewModel,
  ViewMacroScenarioViewModel,
} from "../../../../orval/generated/models";
import { ResultsTabSection } from "./results-tab-section";
import { DataVisualisationTabSection } from "./data-visualisation-tab-section";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

type ScenarioResultsProps = {
  scenario: ViewMacroScenarioViewModel;
  disabled: boolean;
  setScenario: UseMutateAsyncFunction<
    ViewMacroScenarioViewModel,
    any,
    { scenarioId: number; data: UpdateMacroScenarioViewModel },
    unknown
  >;
  handleAdjustInputs?: (adjustment: Adjustment, editName: string) => void;
  handleDeleteAdjustment?: Function;
};

export function ScenarioResults({
  scenario,
  setScenario,
  disabled,
  handleAdjustInputs,
  handleDeleteAdjustment,
}: ScenarioResultsProps) {
  const { t } = useTranslation("macro");
  const [value, setValue] = useState(0);
  const [showAdjustmenPanel, setShowAdjustmenPanel] = useState<boolean>(false);
  const [newAdjustment, setNewAdjustment] = useState<boolean>(false);
  const [editAdjustment, setEditAdjustment] = useState<boolean>(false);
  const [product, setProduct] = useState<Adjustment>({} as Adjustment);
  const [editName, setEditName] = useState<string>("");

  function handleShowAdjustInputs() {
    setShowAdjustmenPanel(true);
  }

  function handleEdit(title: string, item: Adjustment) {
    setEditAdjustment(true);
    setEditName(title);
    setProduct(item);
  }

  return (
    <Box pt={2}>
      <SidePanel
        open={showAdjustmenPanel}
        setOpen={setShowAdjustmenPanel}
        title={t("adjustments")}
        width="550px"
        children={
          <AdjustmentsList
            scenario={scenario}
            adjustmentsList={scenario}
            setAdjustmentsList={setScenario}
            setNewAdjustment={setNewAdjustment}
            handleEdit={handleEdit}
            handleDeleteAdjustment={handleDeleteAdjustment}
          />
        }
      />

      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" fontWeight={500}>
          {t("createScenarioSection.scenarioResultsHeading")}
        </Typography>
        {/*<Tooltip*/}
        {/*  title={*/}
        {/*    disabled ? t("createScenarioSection.adjustInputsTooltipInfo") : ""*/}
        {/*  }*/}
        {/*  placement="top-end"*/}
        {/*  arrow*/}
        {/*>*/}
        {/*  <span>*/}
        {/*    <Button*/}
        {/*      variant="outlined"*/}
        {/*      color="primary"*/}
        {/*      onClick={handleShowAdjustInputs}*/}
        {/*      // TODO: business would like adjustments disabled for now*/}
        {/*        // disabled={disabled}*/}
        {/*      disabled={true}*/}
        {/*    >*/}
        {/*      {t("buttonLabel.addAdjustments")}*/}
        {/*    </Button>*/}
        {/*  </span>*/}
        {/*</Tooltip>*/}
      </Box>
      <Box>
        <Box borderBottom={1} borderColor="divider">
          <CustomTabs
            tabList={scenarioResultsTabs.tabs}
            value={value}
            setValue={setValue}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ResultsTabSection disabled={disabled} scenario={scenario} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DataVisualisationTabSection
            disabled={disabled}
            scenario={scenario}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
