import { createSlice } from "@reduxjs/toolkit";

type brands = {
  id: number;
  brand: string;
  category: string;
  dlz_tag: string;
  cde_tag: string;
};
const initialState: brands[] = [];

export const brandsSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    addBrand(state, action) {
      return [...state, action.payload];
    },
    removeBrand(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
