import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import Modal from "./Modal";
import { Box, Breakpoint } from "@mui/material";

type ModalContextProps = {
  openModal: (
    content: React.ReactNode | JSX.Element,
    navigationAwayLocked?: boolean,
    modalWidth?: false | Breakpoint | undefined,
  ) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export function ModalProvider(props: { children: React.ReactNode }) {
  const [content, setContent] = useState<React.ReactNode | JSX.Element | null>(
    null,
  );
  const [navigationAwayLocked, setNavigationAwayLocked] =
    useState<boolean>(true);

  const [modalWidth, setModalWidth] = useState<false | Breakpoint | undefined>(
    undefined,
  );

  const openModal = useCallback(
    (
      content: React.ReactNode | JSX.Element,
      navigationAwayLocked: boolean = false,
      modalWidth: false | Breakpoint | undefined,
    ) => {
      setContent(content);
      setNavigationAwayLocked(navigationAwayLocked);
      setModalWidth(modalWidth);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setContent(null);
  }, []);

  const ModalContextValue = useMemo(
    () => ({ openModal, closeModal }),
    [openModal, closeModal],
  );

  return (
    <ModalContext.Provider value={ModalContextValue}>
      <Box sx={{ position: !!content ? "fixed" : "auto" }}>
        {props.children}
      </Box>
      {!!content && (
        <Modal
          content={content}
          closeModal={closeModal}
          isOpen={!!content}
          navigationAwayLocked={navigationAwayLocked}
          width={modalWidth}
        />
      )}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}
