import { MRT_ColumnDef } from "material-react-table";
import { Box, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {ViewMacroScenarioViewModel} from "../../../../orval/generated/models";

export function generateTableHeader(scenariosData: ViewMacroScenarioViewModel[]) {
  const tableData: Map<string, any> = new Map();
  const tableColumns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "metric", // To be changed to more suitable name
      header: "",
      size: 200,
      id: "metric",
      enableColumnActions: false,
    },
  ];
  const data: Array<any> = [];
  const dataObj: any = {
    metric: "Metric",
  };
  scenariosData.forEach((scenario: ViewMacroScenarioViewModel) => {
    dataObj[scenario.id!.toString()] = scenario.name;
    tableColumns.push({
      accessorKey: scenario.id!.toString(),
      header: scenario.name,
      id: scenario.id!.toString(),
      Header: ({ column }) => (
        <HeaderTitle column={column} scenario={scenario}></HeaderTitle>
      ),
    });
  });
  data.push(dataObj);
  tableData.set("columns", tableColumns);
  tableData.set("data", data);
  return tableData;
}

function HeaderTitle({ column, scenario }: any) {
  return (
    <Box>
      <Typography fontSize="1rem" fontWeight={600}>
        {column.columnDef.header}
      </Typography>
      <Box display="flex" gap={1}>
        <CalendarTodayIcon sx={{ fontSize: "1rem" }} />
        <Typography fontSize="0.9rem">
          {scenario.inputs.years.base_year} -{" "}
          {scenario.inputs.years.target_year}
        </Typography>
      </Box>
    </Box>
  );
}
