import {
  LinkProps,
  NavLink,
  To,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { Box } from "@mui/material";

export default function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  const className = match ? "active" : "";
  const navigate = useNavigate();
  const handleClick = (route: To) => {
    navigate(route);
  };

  return (
    <Box
      onClick={() => handleClick(to)}
      className={`${className} sidebar-list-item`}
    >
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    </Box>
  );
}
