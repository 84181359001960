import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  list: any[];
  label: string;
  type: string;
  source: string;
  sourceKey: string;
  value?: string | undefined;
  handlerFunction?: (e: SelectChangeEvent) => void;
  handleProductSelect?: (option: string) => void;
  handleUnitSelect?: (option: string) => void;
  isDisabled?: boolean;
  width?: string;
  defaultSelection?: boolean;
  unSelect?: boolean;
  initialValue?: string;
};

export function CustomSelect({
  list,
  label,
  type,
  source,
  sourceKey,
  value,
  handlerFunction,
  handleProductSelect,
  handleUnitSelect,
  isDisabled,
  width,
  defaultSelection,
  unSelect,
  initialValue,
}: Props) {
  const [selectedItem, setSelectedItem] = useState<string>(initialValue || "");
  const { t } = useTranslation(source);

  useEffect(() => {
    if (unSelect) {
      setSelectedItem("");
    }
  }, [unSelect]);

  useEffect(() => {
    if (defaultSelection) {
      if (type === "enum") {
        setSelectedItem(list[0][1]);
      } else {
        setSelectedItem(list[0]);
      }
    }
  }, [list, type, defaultSelection]);

  useEffect(() => {
    if (value) {
      setSelectedItem(value);
    }
  }, [value]);

  const getInitials = (str: string, char: string) => {
    let tempItem = str.toLowerCase().split(char);
    str = `${tempItem[0].slice(0, 1)}${tempItem[1].slice(0, 1)}`;

    return str;
  };

  const handleSelect = (e: SelectChangeEvent) => {
    setSelectedItem(e.target.value);
  };

  const _renderList = (list: any[]) => {
    let renderItem;

    switch (type) {
      case "enum":
        renderItem = list?.map((item, itemIndex) => {
          let formattedItem;

          if (item[0].includes("_")) {
            formattedItem = getInitials(item[0], "_");
          } else {
            formattedItem = item[1];
          }

          return (
            <MenuItem key={itemIndex} value={item[1]}>
              {t(`${sourceKey}.units.${formattedItem}`)}
            </MenuItem>
          );
        });
        break;

      case "array":
        renderItem = list?.map((item, itemIndex) => {
          return (
            <MenuItem key={itemIndex} value={item}>
              {item}
            </MenuItem>
          );
        });
        break;

      case "filters":
        renderItem = list?.map((item, itemIndex) => {
          return (
            <MenuItem
              key={itemIndex}
              value={item}
              data-testid={`filters-custom-select-item-${itemIndex}`}
            >
              {t(`${sourceKey}.units.${item}`)}
            </MenuItem>
          );
        });
        break;

      case "entries":
        renderItem = list?.map(([key, value], itemIndex) => {
          return (
            <MenuItem key={itemIndex} value={key}>
              {key}
            </MenuItem>
          );
        });
        break;

      case "objectArray":
        renderItem = list?.map((value) => {
          return (
            <MenuItem key={value.id} value={value[sourceKey]}>
              {value[sourceKey]}
            </MenuItem>
          );
        });

        break;

      default:
        renderItem = list.map((item: string, itemIndex: number) => (
          <MenuItem key={itemIndex} value={item}>
            {item}
          </MenuItem>
        ));
        break;
    }

    return renderItem;
  };

  return (
    <Box width={width ?? "100%"}>
      <FormControl size="small" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={selectedItem ? selectedItem : ""}
          onChange={(e: any) => {
            handleSelect(e);
            handlerFunction && handlerFunction(e);
            handleProductSelect && handleProductSelect(e.target.value);
            handleUnitSelect && handleUnitSelect(e.target.value);
          }}
          disabled={isDisabled && isDisabled}
        >
          {_renderList(list)}
        </Select>
      </FormControl>
    </Box>
  );
}
