import { ProductConfigurationsMicro } from "../ProductsEditableTable";
import { ManufacturingTableMetrics } from "../ManufacturingTable";
import { BodyAndLidPackagingMetrics } from "../PackagingTable";
import { BodyAndLidEmissionFactorsTableMetrics } from "../BodyAndLidEmissionFactorsTable";
import {
  IngredientViewModel,
  MaterialViewModel,
  SKUAdjustmentAccordionDetails,
} from "../../../../orval/generated/models";
import { LogisticsTableMetrics } from "../LogisticsTable";

export type rowValidationType = {
  metric: string;
  type: "percentage" | "unit";
  isNonZero: boolean;
};

const generateConfig = (
  accordion: SKUAdjustmentAccordionDetails,
  ingredientsData: IngredientViewModel[] | undefined,
  ingredientsEmissionData: IngredientViewModel[] | undefined,
  materialsData: MaterialViewModel[] | undefined,
  t: Function,
): rowValidationType[] => {
  switch (accordion.accordion) {
    case ProductConfigurationsMicro.SERVING_SIZE:
      return [
        {
          metric: "Serving size (ml)",
          type: "unit",
          isNonZero: true,
        },
      ];

    case ProductConfigurationsMicro.MANUFACTURING:
      return ManufacturingTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          type: "unit",
          isNonZero: false,
        };
      });

    case ProductConfigurationsMicro.LOGISTICS:
      return LogisticsTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          type: "unit",
          isNonZero: false,
        };
      });

    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
      return BodyAndLidPackagingMetrics.map((item) => {
        const label = t(item.label);
        return {
          metric: label,
          type: label.includes("(%)") ? "percentage" : "unit",
          isNonZero: label.includes("Net"),
        };
      });

    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
      return BodyAndLidEmissionFactorsTableMetrics.map((item) => {
        return {
          metric: t(item.label),
          type: "unit",
          isNonZero: false,
        };
      });
    case ProductConfigurationsMicro.INGREDIENTS:
      return (
        ingredientsData?.map((ingredient) => ({
          metric: `${ingredient.ingredient} per litre (g/litre)`,
          type: "unit",
          isNonZero: false,
        })) || []
      );
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
      return (
        ingredientsEmissionData?.map((ingredient) => ({
          metric: `${ingredient.ingredient} - EF (g CO₂e/g ingredient)`,
          type: "unit",
          isNonZero: false,
        })) || []
      );

    default:
      if (materialsData) {
        return materialsData.map((material) => ({
          metric: t(`common:${material.material}`),
          type:
            !material.material.includes("weight") &&
            !material.material.includes("ef")
              ? "percentage"
              : "unit",
          isNonZero: false,
        }));
      } else {
        return [];
      }
  }
};

export default generateConfig;
