import { formatText } from "../../../../utils/formatText";
import { timeChartData, timeEvolutionTableData } from "../../types";
import { appTheme } from "../../../../core/theme/theme";
import {
  TableResultsFilterDataType,
  TableResultsType,
} from "../scenario-results/results-tab-section";
import {
  formatNumber,
  formatNumberOrEmpty,
} from "../../../../utils/formatNumber";
import {
  MacroResults,
  MacroResultsByCategory,
  MacroResultsByPackType,
  MekkoBaseGlideScenario,
} from "../../../../orval/generated/models";

const palette = appTheme.palette;

function setYear(
  key: string,
  years: Record<string, number>,
  translations: Record<string, string>,
): string {
  switch (key) {
    case "base":
      return `${years.base_year} ${translations[key]}`;
    case "scenario":
      return `${years.target_year} ${translations[key]}`;
    case "glide":
      return `${years.target_year} ${translations[key]}`;
    default:
      return translations[key];
  }
}

function getSortedCategoryKeys(
  result: MacroResultsByPackType | MacroResultsByCategory,
): string[] {
  return Object.keys(result).sort((a, b) => {
    return a.localeCompare(b); // Alphabetical order
  });
}

function getTimeEvolutionData(
  result: MacroResults,
  years: Record<string, number>,
  translations: Record<string, string>,
  type: TableResultsFilterDataType,
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE,
): timeChartData {
  if (!result) {
    return [];
  }

  const keys: (keyof MekkoBaseGlideScenario)[] = [
    "base",
    "glide",
    "scenario",
  ] as (keyof MekkoBaseGlideScenario)[];
  const categoryKeys = getSortedCategoryKeys(result[sectionType]);
  const chartData = keys.map((key) => ({
    label: setYear(key, years, translations),
    data: [
      ...categoryKeys.map(
        (category: string) => result[sectionType][category][type].total[key],
      ),
      result.by_pillar.total?.[type][key],
    ],
    backgroundColor:
      key === "base"
        ? palette.textColor.light
        : key === "glide"
          ? "#979797"
          : palette.primary.main,
  }));

  return chartData;
}

function getTimeEvolutionLabels(
  result: MacroResultsByPackType | MacroResultsByCategory,
): string[] {
  if (!result) {
    return [];
  }

  const chartLabels = [...getSortedCategoryKeys(result), "Total"];

  return chartLabels;
}

function stringToKey<T>(input: string): T {
  return (input.charAt(0).toLowerCase() +
    input.slice(1).replace(/\s+/g, "_")) as T;
}

function getTitle(
  sectionType: TableResultsType,
  units: TableResultsFilterDataType,
) {
  switch (sectionType) {
    case TableResultsType.BY_CATEGORY:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.categoryAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.categoryPerLitreTitle";
    case TableResultsType.BY_PACK_TYPE:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.byPackAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.byPackPerLitreTitle";
    default:
      return units === TableResultsFilterDataType.ABSOLUTE
        ? "dataVisualisationTabSection.timeEvolution.titles.categoryAbsoluteTitle"
        : "dataVisualisationTabSection.timeEvolution.titles.categoryPerLitreTitle";
  }
}

function getUnitsToDisplay(units: TableResultsFilterDataType) {
  return units === TableResultsFilterDataType.ABSOLUTE
    ? "dataVisualisationTabSection.timeEvolution.units.absoluteDisplayUnits"
    : "dataVisualisationTabSection.timeEvolution.units.perLitreDisplayUnits";
}

function getTableData(
  result: MacroResults,
  type: TableResultsFilterDataType,
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE,
): timeEvolutionTableData {
  if (!result) {
    return [];
  }

  const categoryKeys = getSortedCategoryKeys(result[sectionType]);

  const data = categoryKeys.map((category: string) => {
    const overview_category = result[sectionType][category][type].total;
    return {
      category: formatText(category),
      base_to_glide_change: `${formatNumberOrEmpty(overview_category.base_to_glide_pct_chg, "percentage")} % `,
    };
  });

  data.push({
    category: formatText("Total"),
    base_to_glide_change: `${formatNumberOrEmpty(result.by_pillar.total[type].base_to_glide_pct_chg, "percentage")} % `,
  });

  return data;
}

export {
  getTimeEvolutionData,
  stringToKey,
  getTimeEvolutionLabels,
  getTableData,
  getTitle,
  getUnitsToDisplay,
};
