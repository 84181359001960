import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CustomIcon, Icons } from "../../../../components";
import { TextEdit } from "../../../../components";
import { TextField } from "@mui/material";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";

export type MacroPageHeroSectionProps = {
  onRenameScenario?: (value: string) => void;
  scenario: ViewMacroScenarioViewModel | null;
  onExportScenario: () => void;
  isEditScenario?: boolean;
  handleRenameDescription: Function;
};

export function MacroPageHeroSection({
  onRenameScenario,
  scenario,
  onExportScenario,
  isEditScenario,
  handleRenameDescription,
}: MacroPageHeroSectionProps) {
  const { t } = useTranslation("macro");
  const [name, setName] = useState<string>(scenario?.name || t("defaultTitle"));
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editDescription, setEditDescription] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<
    string | null | undefined
  >(scenario?.description);
  const descriptionPlaceholder =
    descriptionValue && descriptionValue.length > 0
      ? descriptionValue
      : t("noDescription");
  const [descriptionChanged, setDescriptionChanged] = useState<boolean>(false);

  useEffect(() => {
    if (scenario?.description) {
      setDescriptionValue(scenario?.description);
    }
    if (scenario?.name) {
      setName(scenario?.name);
    }
  }, [scenario]);

  const handleRenameScenario = () => {
    setEditMode(true);
  };

  const handleInputSave = (value: string) => {
    if (onRenameScenario) {
      onRenameScenario(value);
      setEditMode(false);
    }
  };

  const handleBlurDescription = (value: string) => {
    if (descriptionChanged) {
      handleRenameDescription(value);
      setDescriptionChanged(false); // Reset descriptionChanged after handling description change
    }
    setEditDescription(false);
  };
  return (
    <Box pr={2} pt={1}>
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        justifyContent={{ md: "space-between" }}
        alignItems={{ xs: "flex-start", md: "center" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth={"80%"}
        >
          {!editMode ? (
            <Box onClick={handleRenameScenario}>
              <Typography className="header-H2 text-ellipsis micro-header">
                {name}
              </Typography>
            </Box>
          ) : (
            <>
              <TextEdit value={name} onSaveInput={handleInputSave}></TextEdit>
            </>
          )}
        </Box>

        <Box>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            disabled={true} // TODO REMOVE
            onClick={onExportScenario}
            startIcon={
              <CustomIcon
                name={Icons.DOWNLOAD_SIMPLE}
                width={"24px"}
                height={"24px"}
                fill="white"
              />
            }
          >
            {t("buttonLabel.exportResults")}
          </Button>
        </Box>
      </Stack>

      <Box width={editDescription ? "100%" : "90%"} minHeight="45px" mt={1}>
        {editDescription ? (
          <TextField
            sx={{ width: "100%" }}
            placeholder={t("noDescription")}
            variant="outlined"
            value={descriptionValue}
            multiline
            hiddenLabel
            onChange={(e) => {
              setDescriptionChanged(true);
              setDescriptionValue(e.target.value);
            }}
            onBlur={(e) => handleBlurDescription(e.target.value)}
            autoFocus
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setDescriptionChanged(true);
                handleBlurDescription((e.target as HTMLInputElement).value);
              }
            }}
            data-testid="text-edit-scenario-description"
          />
        ) : (
          <Typography
            sx={
              isEditScenario
                ? { cursor: "pointer", padding: "14px" }
                : { pointerEvents: "none", padding: "14px" }
            }
            className="description-hover"
            onClick={() => setEditDescription(true)}
          >
            {descriptionPlaceholder}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
