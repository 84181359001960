import "./OptionalMetricsTable.scss";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { MacroTableWrapper } from "../../../../components";
import { Box, Typography, useTheme } from "@mui/material";
import { CustomSelect } from "../../../../components/common/custom-select";
import { useState } from "react";
import {
  OptionalMetricsTableDataItem,
  OptionalMetricsTableResultsFilterDataType,
  OptionalMetricsTableResultsType,
} from "./HelperService";
import { ViewMacroScenarioViewModel } from "../../../../orval/generated/models";
import { useTranslation } from "react-i18next";

export type OptionalMetricsTableProps = {
  columns: MRT_ColumnDef<any>[];
  data: any;
  dataExtractionFunction?: (
    type: OptionalMetricsTableResultsType,
    scenario: ViewMacroScenarioViewModel,
    dataType: OptionalMetricsTableResultsFilterDataType,
  ) => OptionalMetricsTableDataItem[];
  type?: OptionalMetricsTableResultsType;
  title: string;
  graphTable?: boolean;
  subtitle?: string;
  displaySubtitle?: boolean;
  initialFilter?: OptionalMetricsTableResultsFilterDataType;
  showFilterDropdown?: boolean;
};

export const OptionalMetricsTable = ({
  columns,
  data,
  type,
  title,
  graphTable,
  subtitle,
  displaySubtitle,
  initialFilter,
  showFilterDropdown = false,
  dataExtractionFunction,
}: OptionalMetricsTableProps) => {
  const { t } = useTranslation("macro");

  const { palette } = useTheme();
  const [filterType, setFilterType] =
    useState<OptionalMetricsTableResultsFilterDataType>(
      initialFilter ||
        ("absolute" as OptionalMetricsTableResultsFilterDataType),
    );

  const computedData =
    dataExtractionFunction && type
      ? dataExtractionFunction(type, data, filterType)
      : data;
  const table = useMaterialReactTable({
    columns,
    data: computedData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want\
    enableColumnPinning: true,
    initialState: {
      columnPinning: {
        left: ["by_category", "by_pillar", "by_pack_type", "category"],
      },
    },
    muiTableHeadCellProps: ({ column }) => ({
      className: `table-header-cell ${column.id !== columns[0].id ? "middle-header-cells" : ""} ${
        column.id === columns[0].id ? "first-column-cell" : ""
      } ${column.id === columns[columns.length - 1].id ? "last-column" : ""}`,
      sx: {
        color:
          graphTable && column.id === columns[1].id ? "transparent" : "initial",
        borderBottom:
          column.id === columns[0].id
            ? "none"
            : `1px solid ${palette.textColor.light}`,
        borderTop: `1px solid ${palette.textColor.light}`,
        textAlign: "center",
      },
    }),
    muiTableBodyCellProps: ({ column, row, cell }) => ({
      className:
        column.id === columns[0].id &&
        !(
          cell.id.charAt(0) === `${computedData.length - 2}` ||
          cell.id.charAt(0) === `${computedData.length - 1}`
        )
          ? "first-column-cell"
          : column.id === columns[0].id &&
              cell.id.charAt(0) === `${computedData.length - 2}`
            ? "first-column-cell-last"
            : column.id === columns[columns.length - 1].id
              ? "last-column"
              : "",

      sx: {
        borderBottom:
          (cell.id.charAt(0) === `${computedData.length - 1}` ||
            column.id === columns[0].id) &&
          !graphTable
            ? "none"
            : cell.id.charAt(0) === `${computedData.length - 2}` && !graphTable
              ? `1px solid ${palette.primary.main}`
              : `1px solid ${palette.textColor.light}`,
        fontWeight:
          row.id === `${computedData.length - 1}` && !graphTable
            ? "bold"
            : "initial",
        textAlign: "center",
      },
    }),
  });
  const units =
    filterType === "absolute"
      ? t("resultsSection.pillarDeepDiveTable.subtitles.absoluteEmissions")
      : t("resultsSection.pillarDeepDiveTable.subtitles.emissionsPerLitre");

  const subtitleText = subtitle ? subtitle : units;

  return (
    <Box className="displayHeader">
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          margin: "1rem 0",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography className="tableHeader">{title}</Typography>
          {displaySubtitle && subtitleText ? (
            <Typography className="tableSubHeader">{subtitleText}</Typography>
          ) : null}
        </Box>
        {showFilterDropdown ? (
          <Box
            width={"200px"}
            data-testid="optional-metrics-table-filter-dropdown-box"
          >
            <CustomSelect
              list={["absolute", "per_litre"]}
              label={"Filter by"}
              type={"filters"}
              handleProductSelect={(value) =>
                setFilterType(
                  value as OptionalMetricsTableResultsFilterDataType,
                )
              }
              source={"macro"}
              sourceKey={"macro:resultsSection:carbonEmissionOverview:filters"}
              initialValue="absolute"
            />
          </Box>
        ) : null}
      </Box>
      <MacroTableWrapper>
        <Box data-testid="optional-metrics-table-box">
          <MaterialReactTable table={table} />
        </Box>
      </MacroTableWrapper>
    </Box>
  );
};
