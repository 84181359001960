import { AuthSessionStorageKeys } from "../../../features";
import { AppConstant } from "../../../constants";
import { AuthenticationProvders } from "../../../constants/enums";
import {
  clearAuthDataFromSessionStorage,
  isTokenExpired,
} from "../../../features/auth/utils/verifyPwCAccess";

export const useIsUserAuthenticated = () => {
  const appAuthProvider: any = AppConstant.authentication;

  switch (appAuthProvider?.provider) {
    case AuthenticationProvders.IDAM:
      const accessToken = sessionStorage.getItem(
        AuthSessionStorageKeys.accessToken,
      );

      if (accessToken && isTokenExpired(accessToken)) {
        clearAuthDataFromSessionStorage();
        return false;
      }

      return accessToken ? true : false;

    default:
      return false;
  }
};
