import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { MicroTableWrapper } from "../../../../components";
import { COLUMN_WIDTH } from "../../constants";

type Props = {
  columns: MRT_ColumnDef<any>[];
  data: any;
};

export const ProductsTable = ({ columns, data }: Props) => {
  const columns_ = columns.filter(
    (column) => !column.id?.includes("user_data"),
  );

  const table = useMaterialReactTable({
    columns: columns_,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    layoutMode: "grid",
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want,
    defaultColumn: {
      size: COLUMN_WIDTH, //make columns wider by default
    },
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        display: column.id === "metric" ? "block" : "flex",
        alignItems: column.id === "metric" ? "initial" : "center",
        justifyContent: column.id === "metric" ? "initial" : "center",
      },
    }),
  });
  return (
    <MicroTableWrapper>
      <MaterialReactTable table={table} />
    </MicroTableWrapper>
  );
};
