import { RouteObject } from "react-router-dom";
import { RootPage } from "../../../components/sections/rootpage/RootPage";
import ProtectedRoutes from "../../../core/navigation/protectedRoutes";
import { DataManagementRoutesConfig } from "./config";
import DataManagementPage from "../routes/DataManagementPage";

export const DataManagementRoutes: RouteObject = {
  path: DataManagementRoutesConfig.dataManagementPage,
  element: (
    <ProtectedRoutes>
      <RootPage />
    </ProtectedRoutes>
  ),
  children: [
    { index: true, element: <DataManagementPage /> },
    {
      path: DataManagementRoutesConfig.dataManagementPage,
      element: <DataManagementPage />,
    },
  ],
};
