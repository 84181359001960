import { createSlice } from "@reduxjs/toolkit";

type units = {
  id: number;
  name: string;
  grouping: string;
};
const initialState: units[] = [];

export const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    addUnit(state, action) {
      return [...state, action.payload];
    },
    removeUnit(state, action) {
      return state.filter((obj) => `${obj.id}` !== action.payload);
    },
  },
});
