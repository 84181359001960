import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Item } from "./Item";
import { SelectedProductRow } from "./SelectedProductRow";
import { Box, IconButton } from "@mui/material";
import DragIndicator from "@mui/icons-material/DragIndicator";
import {
  Product,
  SKUMinimalViewModel,
} from "../../../orval/generated/models";

interface SortableItemProps {
  id: string;
  item: SKUMinimalViewModel;
  selectedProducts: SKUMinimalViewModel[];
  handleRemoveSelectedProduct: (id: number) => void;
  overlay?: boolean;
  scenarioProduct?: Product;
}

export function SortableItem(props: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      sx={{
        display: "flex",
        border: `solid 0.5px #6A6A6A`,
        borderRadius: 1,
        marginBottom: 1,
        marginRight: 1.25,
        padding: 0.5,
        minWidth: props.overlay === true ? "500px" : null,
        backgroundColor: "white",
        opacity: isDragging && props.overlay === false ? 0.3 : undefined,
        alignItems: "center",
      }}
    >
      <Box width={"10%"}>
        <Item
          id={props.id}
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
        >
          <IconButton aria-label="drag" sx={{ marginRight: 1 }}>
            <DragIndicator />
          </IconButton>
        </Item>
      </Box>
      <SelectedProductRow
        item={props.item}
        handleRemoveSelectedProduct={props.handleRemoveSelectedProduct}
        scenarioProduct={props.scenarioProduct}
      />
    </Box>
  );
}
