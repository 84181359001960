import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import pwcLogo from "../../../assets/images/PwC_Outline_Logo_Black.png";
import "./AuthLayout.scss";
import { AppConstant, EnvironmentVariable } from "../../../constants";
import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AuthSessionStorageKeys } from "../navigation";
import { getIDAMAccessToken, handleAuthObject } from "../utils/verifyPwCAccess";
import { getBaseURL } from "../../../core/http/http-utils";
import { HomeRoutesConfig } from "../../home";
import AuthMessage from "../components/AuthMessage";

export function AuthLayout() {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [accessToken, setAccessToken] = useState(
    sessionStorage.getItem(AuthSessionStorageKeys.accessToken),
  );
  const { idamConfig } = EnvironmentVariable;
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isLogoutPage = location.pathname.includes("logout");

  const _init = useCallback(async () => {
    const code = searchParams.get("code");
    if (code) {
      setIsLoading(true);
      const authObject = await getIDAMAccessToken(code);
      setIsLoading(false);
      handleAuthObject(authObject);
      setAccessToken(authObject?.access_token);
    }
  }, [searchParams]);

  function initateLogin() {
    const redirectURL = `${getBaseURL(idamConfig.appURL)}/auth`;
    const idamLoginURL = `${getBaseURL(idamConfig.loginURL)}client_id=${getBaseURL(idamConfig.clientId)}&redirect_uri=${redirectURL}&response_type=${idamConfig.responseType}&scope=${idamConfig.scope}`;
    window.location.replace(idamLoginURL);
  }

  useEffect(() => {
    _init();
  }, [_init]);

  useEffect(() => {
    if (accessToken) {
      setIsLoading(false);
      navigate(HomeRoutesConfig.homePage);
    }
  }, [accessToken, navigate]);

  return (
    <Box>
      <Grid container component="main" sx={{ minHeight: "100vh" }}>
        <Grid item md={8} xs={12}>
          <Box>
            <img
              src={pwcLogo as string}
              alt={t("PwCLogo")}
              className="landing-logo"
              loading="lazy"
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              sx={{ height: "100vh", marginLeft: "8vw" }}
            >
              <Box>
                <Typography mb={3} variant="h1" sx={{ fontWeight: "500" }}>
                  {AppConstant.appName}
                </Typography>
                {isLogoutPage ? (
                  <AuthMessage
                    message={t("logoutMessage")}
                    buttonText={t("loginBtn")}
                    onButtonClick={initateLogin}
                    isLoading={isLoading}
                  />
                ) : (
                  <AuthMessage
                    message={t("description")}
                    buttonText={t("loginBtn")}
                    onButtonClick={initateLogin}
                    isLoading={isLoading}
                    disableButton={!!accessToken}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4} xs={0} className="landing-image"></Grid>
      </Grid>
    </Box>
  );
}
