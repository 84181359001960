import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { ProductConfigurationsMicro } from "../ProductsEditableTable";
import { useTranslation } from "react-i18next";
import { SUB_ACCORDION_INDENT } from "../../constants";
import { SKUAdjustmentAccordionDetails } from "../../../../orval/generated/models";

export type CustomAccordionProps = {
  title: string;
  details: ReactNode;
  accordion?: SKUAdjustmentAccordionDetails;
  hasSubAccordions?: boolean;
};

function CustomAccordion(props: CustomAccordionProps) {
  const { t } = useTranslation("micro");

  function convertToTitleCase(snakeCaseString: string): string {
    return snakeCaseString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const getTitle = (title: string) => {
    switch (title) {
      case ProductConfigurationsMicro.SERVING_SIZE:
        return t("servingSizeSection.title");
      case ProductConfigurationsMicro.INGREDIENTS:
        return t("ingredientsSection.title");
      case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
        return t("ingredientsEmissionFactorsSection.title");
      case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
        return t("bodyAndLidEmissionFactorsSection.title");
      case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
        return t("packagingSection.title");
      case ProductConfigurationsMicro.LOGISTICS:
        return t("logisticsSection.title");
      case ProductConfigurationsMicro.MANUFACTURING:
        return t("manufacturingSection.title");
      case ProductConfigurationsMicro.OTHER_PACKAGING:
        return t(`otherPackagingSection.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_PRIMARY:
        return t(`otherPackagingSection.Primary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_SECONDARY:
        return t(`otherPackagingSection.Secondary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_TERTIARY:
        return t(`otherPackagingSection.Tertiary.title`);
      case ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS:
        return t(`otherPackagingSection.titleEmissionFactors`);
      default:
        return convertToTitleCase(title);
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
          className="dropdown-left"
        >
          <Typography className="header-H4">{getTitle(props.title)}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={props.hasSubAccordions ? { marginLeft: SUB_ACCORDION_INDENT } : {}}
        >
          {props.details}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CustomAccordion;
